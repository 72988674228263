import { t, Trans } from '@lingui/macro';
import {
  Paper,
  Typography,
  Grid,
  TextField,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  Button,
  TableBody,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/lab';
import { DateTime } from 'luxon';
import { formatNumber } from 'utils/common';
import { useQuery } from 'react-query';
import { enterpriseService } from 'services';
import { Fragment, useMemo, useState } from 'react';
import { TableEmpty } from 'components';
import { EntPaymentDateSchedule, EntPaymentSchedule } from 'types/Enterprise';
const PaymentSchedule = () => {
  const [dateTo, setDateTo] = useState(DateTime.now());
  const [dateFrom, setDateFrom] = useState(DateTime.now());
  const weekSearch = useMemo(() => {
    return {
      dateFrom: dateFrom.toISO(),
      dateTo: dateTo.toISO(),
      limit: 0
    };
  }, [dateFrom, dateTo]);
  const { data } = useQuery(['enterpriseService.fetchEnterprisePaymentSalarySchedules', { ...weekSearch }], () =>
    enterpriseService.getPaymentSchedule({ ...weekSearch }),
  );
  const arr = data?.schedules.filter(x => x.enterprises.length > 0);
  var ents = [] as EntPaymentSchedule[];
  arr?.map((item, index) => {
    ents = ents.concat(item.enterprises);
  });
  return (
    <div className='mt-4'>
      <Typography variant='h4'>
        <Trans>Payment Schedule</Trans>
      </Typography>
      <Grid container columnSpacing={2}>
        <Grid item sm={4}>
          <div className='mt-4 space-x-4 flex items-center'>
            <DesktopDatePicker
              value={dateFrom}
              onChange={(val) => { setDateFrom(val!) }}
              renderInput={(props) => <TextField {...props} />}
              label={t`From`}
              inputFormat='dd/MM/yyyy'
            />
            <DesktopDatePicker
              value={dateTo}
              onChange={(val) => { setDateTo(val!) }}
              renderInput={(props) => <TextField {...props} />}
              label={t`To`}
              inputFormat='dd/MM/yyyy'
            />
          </div>
        </Grid>
        <Grid item sm={8} className='flex space-x-4'>
          <div className='border rounded-lg p-4 flex flex-col justify-center items-center'>
            <Typography variant='subtitle2'>
              <Trans>Total settlement amount receivable (VND)</Trans>
            </Typography>
            <Typography>{formatNumber(data?.totalSalaryPayable)}</Typography>
          </div>
          <div className='border rounded-lg p-4 flex flex-col justify-center items-center'>
            <Typography variant='subtitle2'>
              <Trans>Total fees receivable (VND)</Trans>
            </Typography>
            <Typography>{formatNumber(data?.totalFeePayable)}</Typography>
          </div>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>STT</TableCell>
              <TableCell>Ngày</TableCell>
              <TableCell>Phân loại</TableCell>
              <TableCell>Khách hàng</TableCell>
              <TableCell>Số tiền phải thu (VND)</TableCell>
              {/* <TableCell>Chi tiết</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {ents?.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className='text-center'>{index + 1}</TableCell>
                  <TableCell className='text-center'>{item.date ? DateTime.fromISO(item.date).toFormat('dd/MM/yyyy') : ''}</TableCell>
                  <TableCell className='text-center'>{item.type == 'TRANS' ? 'Tất toán' : 'Phí dịch vụ'}</TableCell>
                  <TableCell className='text-center'>{item.enterpriseName}</TableCell>
                  <TableCell className='text-center'>{formatNumber(item.payableAmount)}</TableCell>
                  {/* <TableCell className='text-center'>
                    <Button size='small' color='info' onClick={() => { }}>
                      <Trans>View</Trans>
                    </Button>
                  </TableCell> */}
                </TableRow>
              )
            })}
            <TableEmpty data={ents} />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PaymentSchedule;
