import { Trans } from '@lingui/macro';
import { Container, Typography } from '@mui/material';
import { IS_EPAYZ } from 'env';
import { EpayzLayout, StaticLayout } from 'layouts';
import { useActiveLocale } from 'hooks/useActiveLocale';

const Layout = IS_EPAYZ ? EpayzLayout : StaticLayout;

const PrivacyPolicy = () => {
  const userLocale = useActiveLocale();
  return (
    <Layout>
      <Container className='py-20 notail'>
        <Typography className='font-bold text-3xl text-center mb-10'>
          <Trans>PRIVACY POLICY</Trans>
        </Typography>

        {IS_EPAYZ ? (
          userLocale === 'vi-VN' ? (
            <>
              <p>
                Thỏa thuận bảo mật (<strong>"Thỏa thuận bảo mật"</strong>) là một phần không thể tách rời của điều khoản
                sử dụng Website điều chỉnh mối quan hệ giữa Công Ty và người dùng truy cập vào website của Công Ty (
                <strong>"Website"</strong>) và/hoặc khách hàng tiềm năng quan tâm đến dịch vụ được cung cấp bởi Công Ty
                (được gọi chung là <strong>"Thành Viên"</strong> hoặc <strong>"Người sử dụng"</strong>) và phải được đọc
                cùng với bất kỳ điều khoản và điều kiện nào được ký kết giữa Người Sử Dụng và Công Ty.
              </p>
              <p>
                <strong>"Dữ liệu cá nhân"</strong> trong Thỏa thuận bảo mật có nghĩa là dữ liệu cho phép nhận dạng một
                cá nhân, bao gồm, nhưng không giới hạn bởi các thông tin như tên, thông tin liên hệ, địa chỉ và các
                thông tin nhận dạng khác của Người Sử Dụng truy cập Website hoặc bất kỳ dịch vụ nào do Công Ty cung cấp
                thông qua Website (<strong>"Dịch Vụ"</strong>) hoặc bất kỳ Dịch Vụ, chữ ký, câu trả lời nào cho các câu
                hỏi nhằm mục đích xác minh bảo mật, số liên lạc khẩn cấp hoặc thông tin liên lạc lại được cung cấp cho
                chúng tôi khi bất kỳ Người Sử Dụng nào truy cập Website.
              </p>
              <p>
                Tùy từng thời điểm, Công Ty có thể thu thập Dữ Liệu Cá Nhân từ (a) Người Sử Dụng, (b) các bên thứ ba,
                bao gồm các chủ sở hữu hưởng lợi của Người Sử Dụng, đối tác, giám đốc, nhân viên hoặc người có thẩm
                quyền ký, người lao động, khách hàng, bên thanh toán, bên nhận thanh toán, bên bảo lãnh, các công ty
                cung cấp dịch vụ bảo mật khác và các thể nhân khác liên quan đến Người Sử Dụng đó (" Các cá nhân liên
                quan ") và/hoặc (c) các nguồn thông tin công cộng có sẵn bao gồm các tổ chức tín dụng, Dữ Liệu Cá Nhân
                về Người Sử Dụng, Các Cá Nhân Liên Quan và các bên liên quan có liên quan đến việc truy cập và sử dụng
                Website hoặc bất kỳ Dịch Vụ nào để cung cấp dịch vụ mang tính cá nhân hóa cao hơn cho Người Sử Dụng của
                chúng tôi. Việc thu thập dữ liệu này có thể bao gồm:
              </p>
              <ol>
                <li>Sử dụng cookies trên Website và trong việc cung cấp Dịch Vụ;</li>
                <li>
                  Thu thập thông tin liên quan đến các thiết bị (bao gồm hệ điều hành thiết bị, phiên bản phần cứng, cài
                  đặt, tên và loại của tệp tin và phần mềm, loại và số nhận dạng thiết bị, vị trí thiết bị, thông tin
                  kết nối như tên của ISP hoặc công ty vận hành thiết bị di động để truy cập Website.
                </li>
              </ol>

              <p>
                Nếu các Thành Viên cung cấp cho Công Ty bất kỳ Dữ Liệu Cá Nhân nào của Thành Viên đó hoặc của các cá
                nhân khác:
              </p>
              <ol>
                <li>
                  Các Thành Viên cam đoan và bảo đảm với Công Ty rằng (x) mỗi cá nhân (bao gồm cả Thành Viên đó) có Dữ
                  Liệu Cá Nhân được cung cấp cho chúng tôi, đã được thông báo về các mục đích của việc thu thập, xử lý,
                  sử dụng hoặc tiết lộ dữ liệu; và (y) cá nhân đó đã chấp thuận và ủy quyền cho việc EPZ thu thập, xử
                  lý, sử dụng và tiết lộ Dữ Liệu Cá Nhân nêu trên.
                </li>
                <li>
                  Các Thành Viên sẽ ngay lập tức thông báo cho chúng tôi khi Thành Viên đó biết về việc bất kỳ cá nhân
                  nào rút lại chấp thuận của cá nhân đó đối với việc Công Ty thu thập, xử lý, sử dụng và/hoặc tiết lộ Dữ
                  Liệu Cá Nhân mà đã được cung cấp cho Công Ty. Việc cá nhân đó rút lại chấp thuận nêu trên có thể ảnh
                  hưởng đến các dịch vụ mà Công Ty cung cấp cho các Thành Viên, cá nhân đó và/hoặc công ty mà Thành Viên
                  đại diện (bao gồm cả việc chấm dứt quan hệ hợp đồng giữa các Thành Viên và chúng tôi, theo các điều
                  khoản chấm dứt liên quan đến người sử dụng đã đăng ký và việc chấm dứt quan hệ hợp đồng nêu trên có
                  thể bị xem là vi phạm các cam đoan và bảo đảm của các Thành Viên với chúng tôi).
                </li>
                <li>
                  Các Thành Viên luôn bảo đảm bất kỳ thông tin nào được cung cấp (bao gồm bất kỳ Dữ Liệu Cá Nhân nào)
                  cho chúng tôi là đúng, chính xác và đầy đủ, và bất kỳ chấp thuận nào được đưa ra liên quan đến Dữ Liệu
                  Cá Nhân sẽ, phụ thuộc vào pháp luật và quy định hiện hành, sẽ duy trì hiệu lực nếu bất kỳ cá nhân nào
                  nêu trên chết, mất năng lực hành vi, phá sản hoặc mất khả năng thanh toán và việc chấm dứt hoặc hết
                  hạn của bất kỳ tài khoản nào liên quan đến việc sử dụng Website.
                </li>
              </ol>

              <p>
                Dữ Liệu Cá Nhân được gửi cho Công Ty hoặc do Công Ty thu thập có thể được Công Ty sử dụng vì bất kỳ mục
                đích kinh doanh nào của Công Ty, bao gồm (“Mục Đích”):
              </p>
              <ol>
                <li>
                  Để vận hành, quản trị bất kỳ tài khoản nào và cung cấp Dịch Vụ cho bất kỳ Người Sử Dụng nào thông qua
                  Website bao gồm, nhưng không giới hạn bởi, hỗ trợ chuyển tiền, duy trì thông tin “nhận diện khách
                  hàng” chính xác, để xác minh danh tính hoặc thẩm quyền của Người Sử Dụng và/hoặc người đại diện của
                  Người Sử Dụng mà liên hệ với Công Ty hoặc có thể được Công Ty liên hệ, để thực hiện hoặc phản hồi các
                  yêu cầu, câu hỏi hoặc chỉ thị từ Người Sử Dụng và/hoặc người đại diện của Người Sử Dụng, và để thực
                  hiện mô hình hóa hoặc phân tích rủi ro;
                </li>
                <li>Để tạo điều kiện thuận lợi cho các giao dịch trên Website hoặc liên quan đến Dịch Vụ;</li>
                <li>
                  Để tuân thủ các điều khoản của điều khoản sử dụng Website, Thỏa thuận bảo mật và bất kỳ điều khoản và
                  điều kiện nào khác mà Công Ty có thể quy định tại từng thời điểm và vào bất kỳ thời điểm nào;
                </li>
                <li>
                  Để xác minh danh tính hoặc thẩm quyền của Người Sử Dụng và/hoặc người đại diện của Người Sử Dụng
                  và/hoặc theo quy trình bảo mật hiện hành hoặc quy trình nội bộ của Công Ty;
                </li>
                <li>
                  Trong quy trình phân tích và đánh giá khả năng tồn tại, độ tin cậy, tính phù hợp, tính đủ điều kiện và
                  danh tính của Người Sử Dụng liên quan đến Dịch Vụ, và/hoặc bất kỳ tài liệu nào khác được ký kết hoặc
                  đồng ý bởi Người Sử Dụng đó; 6. Để thực hiện, giám sát và phân tích hoạt động kinh doanh của Công Ty;
                </li>
                <li>Để thực hiện, giám sát và phân tích hoạt động kinh doanh của Công Ty;</li>
                <li>
                  Cho hoạt động tiếp thị, quảng bá hoặc quảng cáo các sản phẩm và dịch vụ (bao gồm bất kỳ dịch vụ tài
                  chính nào khác) mà Công Ty tin rằng các Thành Viên có thể quan tâm;
                </li>
                <li>
                  Là một phần hoặc liên quan đến, bất kỳ hoạt động mua bán, sáp nhập, hoặc bất kỳ thay đổi tương tự nào
                  đối với hoạt động kinh doanh của Công Ty;
                </li>
                <li>
                  Đối với phản hồi và khiếu nại của khách hàng hoặc các mục đích pháp lý trong việc thực thi các quyền
                  của Công Ty, soạn thảo và rà soát các tài liệu, bảo đảm tham vấn pháp lý và giải quyết tranh chấp;
                </li>
                <li>Để tuân thủ bất kỳ quy định pháp luật hiện hành nào tại bất kỳ quốc gia nào; </li>
                <li>
                  Để phát hiện, ngăn chặn và điều tra hành vi gian lận, tội phạm, rửa tiền, tài trợ chống khủng bố và
                  hối lộ
                </li>
                <li>Các mục đích hợp lý liên quan đến các mục đích nêu trên.</li>
              </ol>

              <p>
                Công Ty có thể lưu trữ Dữ Liệu Cá Nhân miễn là một hoặc một số mục đích mà theo đó dữ liệu đó được thu
                thập vẫn hợp lệ, theo yêu cầu của quy định pháp luật bao gồm các quyền tài phán khác bên ngoài Việt Nam,
                hoặc theo yêu cầu của chính sách lưu giữ hồ sơ hoặc mục đích kinh doanh của Công Ty.Tùy từng thời điểm,
                Công Ty cũng có thể tiết lộ Dữ Liệu Cá Nhân vì bất kỳ Mục Đích nào cho các cổ đông, giám đốc, nhân viên
                và người lao động của Công Ty và các bên dưới đây (dù ở trong hay ngoài phạm vi lãnh thổ Việt Nam):
              </p>
              <ol>
                <li>
                  Bất kỳ đại lý, nhà thầu, hoặc bên thứ ba cung cấp dịch vụ nào cung cấp các dịch vụ ngân hàng, chuyển
                  tiền, hành chính, thư tín, viễn thông, tổng đài, quy trình kinh doanh, du lịch, thị thực, quản trị
                  kiến thức, nhân sự, xử lý dữ liệu, công nghệ thông tin, máy tính, thanh toán, thu hồi nợ, kiểm tra
                  tham chiếu tín dụng hoặc thanh toán bù trừ chứng khoán, mô hình hóa hoặc phân tích rủi ro hoặc các
                  dịch vụ khác cho EPZ liên quan đến hoạt động kinh doanh của Công Ty và/hoặc hoạt động của Website hoặc
                  bất kỳ Dịch Vụ nào;
                </li>
                <li>
                  Bất kỳ cá nhân hoặc tổ chức nào được thuê bởi hoặc nhân danh Công Ty hoặc là một phần hoặc liên quan
                  đến bất kỳ nhóm công ty nào mà Công Ty là một thành viên trong nhóm hoặc có liên kết với nhóm công ty
                  đó;
                </li>
                <li>
                  Bất kỳ cá nhân hoặc tổ chức nào mà Công Ty có nghĩa vụ hoặc bằng cách khác được yêu cầu tiết lộ theo
                  bất kỳ văn bản quy phạm pháp luật và quy định hiện hành nào, bao gồm việc tiết lộ cho tòa án, và/hoặc
                  các cơ quan pháp lý, quản lý, thuế và chính phủ tại Việt Nam và sở giao dịch chứng khoán;
                </li>
                <li>
                  Bất kỳ bên nào đưa ra hoặc đề nghị cấp bảo lãnh hoặc bảo đảm của bên thứ ba để bảo lãnh hoặc bảo đảm
                  các nghĩa vụ của bất kỳ người dùng nào;
                </li>
                <li>Bất kỳ bên nào có mục đích tiến hành kiểm tra với Cơ quan Đăng ký Không Gọi;</li>
                <li>
                  Bất kỳ bên nào vì mục đích đáp ứng hoặc tuân thủ các chính sách và thủ tục nội bộ của Công Ty và bất
                  kỳ quy tắc, luật, quy định, quy tắc thực hành hoặc chỉ thị, lệnh hoặc yêu cầu do bất kỳ tòa án, cơ
                  quan pháp lý hoặc cơ quan nhà nước nào bao gồm cả các quyền tài phán khác bên ngoài Việt Nam ban hành
                  (bao gồm nhưng không giới hạn bởi việc tiết lộ cho các cơ quan quản lý, tiến hành kiểm tra kiểm toán
                  hoặc bất kỳ cuộc điều tra nào)
                </li>
                <li>
                  Bất kỳ bên được chuyển giao thực tế hoặc đề xuất chuyển giao nào của EPZ hoặc bên được chuyển nhượng
                  các quyền hợp lệ của EPZ đối với toàn bộ hoặc bất kỳ phần nào của tài sản hoặc hoạt động kinh doanh
                  của EPZ;
                </li>
                <li>
                  Công ty tín dụng cũng như các Thành Viên của công ty tín dụng đó và các bên thứ ba khác liên quan đến
                  việc sử dụng Website theo yêu cầu của pháp luật hoặc quy định.
                </li>
              </ol>

              <p>
                Không ảnh hưởng đến các điều khoản nêu trên, Công Ty có thể tiết lộ Dữ Liệu Cá Nhân cho bất kỳ cá nhân
                hoặc tổ chức nào mà Công Ty có nghĩa vụ hoặc bằng cách khác phải tiết lộ theo bất kỳ văn bản quy phạm
                pháp luật và quy định hiện hành nào, bao gồm việc tiết lộ cho tòa án, trọng tài và/hoặc các cơ quan pháp
                lý, quản lý, thuế và chính phủ tại Việt Nam hoặc cơ quan khác.
              </p>
              <p>
                Các Thành Viên có thể truy cập hoặc sửa đổi bất kỳ Dữ Liệu Cá Nhân nào của các Thành Viên do Công Ty nắm
                giữ. Để thực hiện việc đó, vui lòng liên hệ với Công Ty theo địa chỉ email{' '}
                <strong>support@EPZ.com.vn</strong>. Công Ty, theo toàn quyền quyết định của Công Ty, có quyền tính phí
                đối với việc cấp quyền truy cập và sửa đổi Dữ Liệu Cá Nhân nêu trên nếu Công Ty cho là phù hợp.
              </p>
              <p>
                Tùy từng thời điểm, Công Ty có thể gửi email hoặc tin nhắn (<strong>"SMS"</strong>) đến số điện thoại di
                động của các Thành Viên và/hoặc Các Cá Nhân Liên Quan dựa trên Dữ Liệu Cá Nhân được cung cấp cho Công
                Ty. Công Ty không bảo đảm về việc các tin nhắn này là hoàn toàn an toàn. Trong mọi trường hợp, Công Ty
                không chịu trách nhiệm đối với bất kỳ thiệt hại nào phát sinh hoặc gánh chịu bởi các Thành Viên hoặc Các
                Cá Nhân Liên Quan liên quan đến hoặc phát sinh từ bất kỳ tin nhắn nào được gửi bởi Công Ty hoặc tới Công
                Ty.
              </p>
            </>
          ) : (
            <>
              <p>
                The privacy policy (<strong>"The privacy policy"</strong>) is an integral part of the Website terms of
                use governing the relationship between the Company and the user who accesses to the Company's website (
                <strong>"Website"</strong>) and/or a potential customer who is interest in the service provided by the
                Company (collectively referred to as the
                <strong>"Member"</strong> or the <strong>"User"</strong>) and should be read in conjunction with any of
                the terms and conditions entered into between the User and the Company.
              </p>
              <p>
                <strong>"Personal Data"</strong> in the Privacy Policy means data which enables an individual to be
                identified including, but not limited to, information such as names, contact details, addresses and
                other identification details of the User who access the Website or any of the services provided by the
                Company through the Website (<strong>"Services"</strong>) or any of the Services, signatures, answers to
                questions intended for security verification, emergency contact numbers or call back contact details
                provided or made available to us when any User accesses the Website.
              </p>
              <p>
                From time to time, the Company may collect Personal Data from (a) the User, (b) third parties, including
                User's beneficial owners, partners, directors, officers or authorized signatories, employees, customers,
                payors, payees, guarantors, other security providers and other natural persons related to that user (the
                " Relevant Individuals ") and/or (c) publicly available sources including credit bureaus, Personal Data
                about the Users, Relevant Individuals and related parties in connection with the access and use of the
                Website or any of the Services to provide more personalized service for our User. This may include:
              </p>
              <ol>
                <li>The use of cookies in the Website and in the provision of the Services;</li>
                <li>
                  Collection of information in relation to the devices (including device operating system, hardware
                  version, settings, file and software names and types and device identifiers, device location,
                  connection information such as the name of the ISP or mobile operator to access the Website.
                </li>
              </ol>

              <p>Should the Member provide the Company any Personal Data of yourself or others:</p>
              <ol>
                <li>
                  The Member represents and warrants to the Company that (x) each individual (including yourself) whose
                  Personal Data has been provided to us, has been notified of the purposes for which data will be
                  collected, processed, used or disclosed; and (y) such individual's consent for the collection,
                  processing, use and disclosure of such Personal Data by EPZ has been obtained and authorized by such
                  individual;
                </li>
                <li>
                  The Member shall notify us immediately upon becoming aware of any withdrawal by any individual of
                  his/her consent to the collection, processing, use and/or disclosure by the Company of his/her
                  Personal Data provided to the Company. The withdrawal of such consent may affect the services which
                  the Company is able to provide to the Members, such individual and/or the corporate the Members
                  represent (including possible termination of contractual relationship of the Members with us, subject
                  to termination provisions relating to registered users and may be deemed a breach of representation
                  and undertakings of the Member to us).
                </li>
                <li>
                  The Member shall ensure at all times that any information provided (including any Personal Data) to us
                  is correct, accurate and complete, and that any consent given in relation to Personal Data shall,
                  subject to all applicable laws and regulations, survive death, incapacity, bankruptcy or insolvency of
                  any such individual and the termination or expiration of any account in connection with the use of the
                  Website.
                </li>
              </ol>

              <p>
                Personal Data submitted or collected by the Company may be used by the Company for any of its business
                purposes, including (the "Purposes"):
              </p>
              <ol>
                <li>
                  To operate, administer any account and to provide Services to any User through the Website including,
                  but not limited to, facilitating the transfer of funds, maintaining accurate "know your customer"
                  information, to verify the identities or authorities of the User and/or their representatives who
                  contact the Company or may be contacted by the Company, to carry out or respond to requests, questions
                  or instructions from the User and/or their representatives, and to perform risk modeling or analysis;
                </li>
                <li>To facilitate transactions on the Website or in connection with the Services;</li>
                <li>
                  To comply with the terms of use of the Website, the Privacy Policy and any other terms and conditions
                  as may be stipulated by the Company from time to time and at any time;
                </li>
                <li>
                  To verify the identity or authority of the User and/or representatives of the User and/or to pursuant
                  to current security procedures or internal procedures of the Company;
                </li>
                <li>
                  In the assessment and evaluation of viability, credibility, suitability, eligibility and identity of
                  the User in connection with the Services, and/or any other documents entered into or agreed to by such
                  User;
                </li>
                <li>To carry out, monitor and analyze the Company' business;</li>
                <li>
                  For the marketing, promotion or advertisement of products and services (including any other financial
                  services) which the Company believes the Member may be interested in;
                </li>
                <li>
                  As part of, or in connection with, any sale, merger, or similar change of the Company's business;
                </li>
                <li>
                  For customer feedback and complaints or legal purposes in enforcing the Company's rights, drafting and
                  reviewing of documents, procuring legal advice and dispute resolution;
                </li>
                <li>To comply with any applicable laws, rules, or regulations in any country;</li>
                <li>
                  To detect, prevent and investigate fraud, crime, offenses, money-laundering, counter-terrorist
                  financing and bribery; and
                </li>
                <li>Purposes reasonably related to the aforesaid.</li>
              </ol>

              <p>
                The Company may retain Personal Data for so long as one or more of the purpose for which it was
                collected remains valid, where required by regulation or law including other jurisdictions outside of
                Vietnam, or as required by its own record retention policies or own business purposes. The Company may
                from time to time also disclose Personal Data for any of the Purposes to the Company's shareholders,
                directors, officers and employees and the following parties (whether inside or outside of Vietnam):
              </p>
              <ol>
                <li>
                  Any agent, contractor, or third party service provider who provides banking, remittance,
                  administrative, mailing, telecommunications, call centers, business process, travel, visa, knowledge
                  management, human resource, data processing, information technology, computer, payment, debt
                  collection, credit reference checks or securities clearing or risk modeling or analysis or other
                  services to EPZ in connection with the business operations of the Company and/or the operation of the
                  Website or any of the Services;
                </li>
                <li>
                  Any person or entity employed by or on behalf of the Company or is a part of or related to any group
                  of companies of which the Company forms part or is affiliated to;
                </li>
                <li>
                  Any person or entity to whom the Company is under an obligation or otherwise required to make
                  disclosure pursuant to any applicable laws and regulations, including disclosure to courts, tribunals,
                  and/or legal, regulatory, tax and government authorities and stock exchanges;
                </li>
                <li>
                  Any party giving or proposing to give a guarantee or third-party security to guarantee or secure the
                  obligations of any User;
                </li>
                <li>Any party for purposes of conducting checks with the Do Not Call Registry;</li>
                <li>
                  Any party for purposes of meeting or complying with the Company's internal policies and procedures and
                  any applicable rules, laws, regulations, codes of practice or guidelines, orders or requests issued by
                  any court, legal or regulatory bodies including other jurisdictions outside of Vietnam (including but
                  not limited to disclosures to regulatory bodies, conducting audit checks or any investigations);
                </li>
                <li>
                  Any actual or proposed assignee of EPZ or transferee of EPZ' rights in respect of all or any part of
                  the assets or business of EPZ; and
                </li>
                <li>
                  Any credit bureau as well as the members of such credit bureau and other relevant third parties in
                  connection with the use of the Website as required by law or regulation.
                </li>
              </ol>

              <p>
                Without prejudice to the foregoing, the Company may disclose Personal Data to any person or entity to
                whom the Company is under an obligation or otherwise required to make disclosure pursuant to any
                applicable laws and regulations, including disclosure to courts, tribunals, and/or legal, regulatory,
                tax and government authorities in Vietnam or otherwise.
              </p>
              <p>
                The Members may access or make corrections to any Personal Data of the Members held by the Company. To
                do so, please contact the Company at <strong>support@EPZ.com.vn</strong>. The Company reserves the right
                to charge the fees as it may, in its sole and absolute discretion, deem appropriate for the grant of
                such access and to correct any Personal Data.
              </p>
              <p>
                The Company may from time to time send the Members and/or the Relevant Individuals email messages or
                short message service (<strong>"SMS"</strong>) to mobile phones based on Personal Data provided to the
                Company. The Company does not guarantee that these messages will be completely secure. Under no
                circumstance will the Company be liable for any damages incurred or sustained by the Members or the
                Relevant Individuals in connection with or arising from any such messages transmitted by the Company or
                to the Company.
              </p>
            </>
          )
        ) : userLocale === 'vi-VN' ? (
          <>
            <p>
              Thỏa thuận bảo mật (<strong>"Thỏa thuận bảo mật"</strong>) là một phần không thể tách rời của điều khoản
              sử dụng Website điều chỉnh mối quan hệ giữa Công Ty và người dùng truy cập vào website của Công Ty (
              <strong>"Website"</strong>) và/hoặc khách hàng tiềm năng quan tâm đến dịch vụ được cung cấp bởi Công Ty
              (được gọi chung là <strong>"Thành Viên"</strong> hoặc <strong>"Người sử dụng"</strong>) và phải được đọc
              cùng với bất kỳ điều khoản và điều kiện nào được ký kết giữa Người Sử Dụng và Công Ty.
            </p>
            <p>
              <strong>"Dữ liệu cá nhân"</strong> trong Thỏa thuận bảo mật có nghĩa là dữ liệu cho phép nhận dạng một cá
              nhân, bao gồm, nhưng không giới hạn bởi các thông tin như tên, thông tin liên hệ, địa chỉ và các thông tin
              nhận dạng khác của Người Sử Dụng truy cập Website hoặc bất kỳ dịch vụ nào do Công Ty cung cấp thông qua
              Website (<strong>"Dịch Vụ"</strong>) hoặc bất kỳ Dịch Vụ, chữ ký, câu trả lời nào cho các câu hỏi nhằm mục
              đích xác minh bảo mật, số liên lạc khẩn cấp hoặc thông tin liên lạc lại được cung cấp cho chúng tôi khi
              bất kỳ Người Sử Dụng nào truy cập Website.
            </p>
            <p>
              Tùy từng thời điểm, Công Ty có thể thu thập Dữ Liệu Cá Nhân từ (a) Người Sử Dụng, (b) các bên thứ ba, bao
              gồm các chủ sở hữu hưởng lợi của Người Sử Dụng, đối tác, giám đốc, nhân viên hoặc người có thẩm quyền ký,
              người lao động, khách hàng, bên thanh toán, bên nhận thanh toán, bên bảo lãnh, các công ty cung cấp dịch
              vụ bảo mật khác và các thể nhân khác liên quan đến Người Sử Dụng đó (" Các cá nhân liên quan ") và/hoặc
              (c) các nguồn thông tin công cộng có sẵn bao gồm các tổ chức tín dụng, Dữ Liệu Cá Nhân về Người Sử Dụng,
              Các Cá Nhân Liên Quan và các bên liên quan có liên quan đến việc truy cập và sử dụng Website hoặc bất kỳ
              Dịch Vụ nào để cung cấp dịch vụ mang tính cá nhân hóa cao hơn cho Người Sử Dụng của chúng tôi. Việc thu
              thập dữ liệu này có thể bao gồm:
            </p>
            <ol>
              <li>Sử dụng cookies trên Website và trong việc cung cấp Dịch Vụ;</li>
              <li>
                Thu thập thông tin liên quan đến các thiết bị (bao gồm hệ điều hành thiết bị, phiên bản phần cứng, cài
                đặt, tên và loại của tệp tin và phần mềm, loại và số nhận dạng thiết bị, vị trí thiết bị, thông tin kết
                nối như tên của ISP hoặc công ty vận hành thiết bị di động để truy cập Website.
              </li>
            </ol>

            <p>
              Nếu các Thành Viên cung cấp cho Công Ty bất kỳ Dữ Liệu Cá Nhân nào của Thành Viên đó hoặc của các cá nhân
              khác:
            </p>
            <ol>
              <li>
                Các Thành Viên cam đoan và bảo đảm với Công Ty rằng (x) mỗi cá nhân (bao gồm cả Thành Viên đó) có Dữ
                Liệu Cá Nhân được cung cấp cho chúng tôi, đã được thông báo về các mục đích của việc thu thập, xử lý, sử
                dụng hoặc tiết lộ dữ liệu; và (y) cá nhân đó đã chấp thuận và ủy quyền cho việc SCP thu thập, xử lý, sử
                dụng và tiết lộ Dữ Liệu Cá Nhân nêu trên.
              </li>
              <li>
                Các Thành Viên sẽ ngay lập tức thông báo cho chúng tôi khi Thành Viên đó biết về việc bất kỳ cá nhân nào
                rút lại chấp thuận của cá nhân đó đối với việc Công Ty thu thập, xử lý, sử dụng và/hoặc tiết lộ Dữ Liệu
                Cá Nhân mà đã được cung cấp cho Công Ty. Việc cá nhân đó rút lại chấp thuận nêu trên có thể ảnh hưởng
                đến các dịch vụ mà Công Ty cung cấp cho các Thành Viên, cá nhân đó và/hoặc công ty mà Thành Viên đại
                diện (bao gồm cả việc chấm dứt quan hệ hợp đồng giữa các Thành Viên và chúng tôi, theo các điều khoản
                chấm dứt liên quan đến người sử dụng đã đăng ký và việc chấm dứt quan hệ hợp đồng nêu trên có thể bị xem
                là vi phạm các cam đoan và bảo đảm của các Thành Viên với chúng tôi).
              </li>
              <li>
                Các Thành Viên luôn bảo đảm bất kỳ thông tin nào được cung cấp (bao gồm bất kỳ Dữ Liệu Cá Nhân nào) cho
                chúng tôi là đúng, chính xác và đầy đủ, và bất kỳ chấp thuận nào được đưa ra liên quan đến Dữ Liệu Cá
                Nhân sẽ, phụ thuộc vào pháp luật và quy định hiện hành, sẽ duy trì hiệu lực nếu bất kỳ cá nhân nào nêu
                trên chết, mất năng lực hành vi, phá sản hoặc mất khả năng thanh toán và việc chấm dứt hoặc hết hạn của
                bất kỳ tài khoản nào liên quan đến việc sử dụng Website.
              </li>
            </ol>

            <p>
              Dữ Liệu Cá Nhân được gửi cho Công Ty hoặc do Công Ty thu thập có thể được Công Ty sử dụng vì bất kỳ mục
              đích kinh doanh nào của Công Ty, bao gồm (“Mục Đích”):
            </p>
            <ol>
              <li>
                Để vận hành, quản trị bất kỳ tài khoản nào và cung cấp Dịch Vụ cho bất kỳ Người Sử Dụng nào thông qua
                Website bao gồm, nhưng không giới hạn bởi, hỗ trợ chuyển tiền, duy trì thông tin “nhận diện khách hàng”
                chính xác, để xác minh danh tính hoặc thẩm quyền của Người Sử Dụng và/hoặc người đại diện của Người Sử
                Dụng mà liên hệ với Công Ty hoặc có thể được Công Ty liên hệ, để thực hiện hoặc phản hồi các yêu cầu,
                câu hỏi hoặc chỉ thị từ Người Sử Dụng và/hoặc người đại diện của Người Sử Dụng, và để thực hiện mô hình
                hóa hoặc phân tích rủi ro;
              </li>
              <li>Để tạo điều kiện thuận lợi cho các giao dịch trên Website hoặc liên quan đến Dịch Vụ;</li>
              <li>
                Để tuân thủ các điều khoản của điều khoản sử dụng Website, Thỏa thuận bảo mật và bất kỳ điều khoản và
                điều kiện nào khác mà Công Ty có thể quy định tại từng thời điểm và vào bất kỳ thời điểm nào;
              </li>
              <li>
                Để xác minh danh tính hoặc thẩm quyền của Người Sử Dụng và/hoặc người đại diện của Người Sử Dụng và/hoặc
                theo quy trình bảo mật hiện hành hoặc quy trình nội bộ của Công Ty;
              </li>
              <li>
                Trong quy trình phân tích và đánh giá khả năng tồn tại, độ tin cậy, tính phù hợp, tính đủ điều kiện và
                danh tính của Người Sử Dụng liên quan đến Dịch Vụ, và/hoặc bất kỳ tài liệu nào khác được ký kết hoặc
                đồng ý bởi Người Sử Dụng đó; 6. Để thực hiện, giám sát và phân tích hoạt động kinh doanh của Công Ty;
              </li>
              <li>Để thực hiện, giám sát và phân tích hoạt động kinh doanh của Công Ty;</li>
              <li>
                Cho hoạt động tiếp thị, quảng bá hoặc quảng cáo các sản phẩm và dịch vụ (bao gồm bất kỳ dịch vụ tài
                chính nào khác) mà Công Ty tin rằng các Thành Viên có thể quan tâm;
              </li>
              <li>
                Là một phần hoặc liên quan đến, bất kỳ hoạt động mua bán, sáp nhập, hoặc bất kỳ thay đổi tương tự nào
                đối với hoạt động kinh doanh của Công Ty;
              </li>
              <li>
                Đối với phản hồi và khiếu nại của khách hàng hoặc các mục đích pháp lý trong việc thực thi các quyền của
                Công Ty, soạn thảo và rà soát các tài liệu, bảo đảm tham vấn pháp lý và giải quyết tranh chấp;
              </li>
              <li>Để tuân thủ bất kỳ quy định pháp luật hiện hành nào tại bất kỳ quốc gia nào; </li>
              <li>
                Để phát hiện, ngăn chặn và điều tra hành vi gian lận, tội phạm, rửa tiền, tài trợ chống khủng bố và hối
                lộ
              </li>
              <li>Các mục đích hợp lý liên quan đến các mục đích nêu trên.</li>
            </ol>

            <p>
              Công Ty có thể lưu trữ Dữ Liệu Cá Nhân miễn là một hoặc một số mục đích mà theo đó dữ liệu đó được thu
              thập vẫn hợp lệ, theo yêu cầu của quy định pháp luật bao gồm các quyền tài phán khác bên ngoài Việt Nam,
              hoặc theo yêu cầu của chính sách lưu giữ hồ sơ hoặc mục đích kinh doanh của Công Ty.Tùy từng thời điểm,
              Công Ty cũng có thể tiết lộ Dữ Liệu Cá Nhân vì bất kỳ Mục Đích nào cho các cổ đông, giám đốc, nhân viên và
              người lao động của Công Ty và các bên dưới đây (dù ở trong hay ngoài phạm vi lãnh thổ Việt Nam):
            </p>
            <ol>
              <li>
                Bất kỳ đại lý, nhà thầu, hoặc bên thứ ba cung cấp dịch vụ nào cung cấp các dịch vụ ngân hàng, chuyển
                tiền, hành chính, thư tín, viễn thông, tổng đài, quy trình kinh doanh, du lịch, thị thực, quản trị kiến
                thức, nhân sự, xử lý dữ liệu, công nghệ thông tin, máy tính, thanh toán, thu hồi nợ, kiểm tra tham chiếu
                tín dụng hoặc thanh toán bù trừ chứng khoán, mô hình hóa hoặc phân tích rủi ro hoặc các dịch vụ khác cho
                SCP liên quan đến hoạt động kinh doanh của Công Ty và/hoặc hoạt động của Website hoặc bất kỳ Dịch Vụ
                nào;
              </li>
              <li>
                Bất kỳ cá nhân hoặc tổ chức nào được thuê bởi hoặc nhân danh Công Ty hoặc là một phần hoặc liên quan đến
                bất kỳ nhóm công ty nào mà Công Ty là một thành viên trong nhóm hoặc có liên kết với nhóm công ty đó;
              </li>
              <li>
                Bất kỳ cá nhân hoặc tổ chức nào mà Công Ty có nghĩa vụ hoặc bằng cách khác được yêu cầu tiết lộ theo bất
                kỳ văn bản quy phạm pháp luật và quy định hiện hành nào, bao gồm việc tiết lộ cho tòa án, và/hoặc các cơ
                quan pháp lý, quản lý, thuế và chính phủ tại Việt Nam và sở giao dịch chứng khoán;
              </li>
              <li>
                Bất kỳ bên nào đưa ra hoặc đề nghị cấp bảo lãnh hoặc bảo đảm của bên thứ ba để bảo lãnh hoặc bảo đảm các
                nghĩa vụ của bất kỳ người dùng nào;
              </li>
              <li>Bất kỳ bên nào có mục đích tiến hành kiểm tra với Cơ quan Đăng ký Không Gọi;</li>
              <li>
                Bất kỳ bên nào vì mục đích đáp ứng hoặc tuân thủ các chính sách và thủ tục nội bộ của Công Ty và bất kỳ
                quy tắc, luật, quy định, quy tắc thực hành hoặc chỉ thị, lệnh hoặc yêu cầu do bất kỳ tòa án, cơ quan
                pháp lý hoặc cơ quan nhà nước nào bao gồm cả các quyền tài phán khác bên ngoài Việt Nam ban hành (bao
                gồm nhưng không giới hạn bởi việc tiết lộ cho các cơ quan quản lý, tiến hành kiểm tra kiểm toán hoặc bất
                kỳ cuộc điều tra nào)
              </li>
              <li>
                Bất kỳ bên được chuyển giao thực tế hoặc đề xuất chuyển giao nào của SCP hoặc bên được chuyển nhượng các
                quyền hợp lệ của SCP đối với toàn bộ hoặc bất kỳ phần nào của tài sản hoặc hoạt động kinh doanh của SCP;
              </li>
              <li>
                Công ty tín dụng cũng như các Thành Viên của công ty tín dụng đó và các bên thứ ba khác liên quan đến
                việc sử dụng Website theo yêu cầu của pháp luật hoặc quy định.
              </li>
            </ol>

            <p>
              Không ảnh hưởng đến các điều khoản nêu trên, Công Ty có thể tiết lộ Dữ Liệu Cá Nhân cho bất kỳ cá nhân
              hoặc tổ chức nào mà Công Ty có nghĩa vụ hoặc bằng cách khác phải tiết lộ theo bất kỳ văn bản quy phạm pháp
              luật và quy định hiện hành nào, bao gồm việc tiết lộ cho tòa án, trọng tài và/hoặc các cơ quan pháp lý,
              quản lý, thuế và chính phủ tại Việt Nam hoặc cơ quan khác.
            </p>
            <p>
              Các Thành Viên có thể truy cập hoặc sửa đổi bất kỳ Dữ Liệu Cá Nhân nào của các Thành Viên do Công Ty nắm
              giữ. Để thực hiện việc đó, vui lòng liên hệ với Công Ty theo địa chỉ email{' '}
              <strong>support@scp.com.vn</strong>. Công Ty, theo toàn quyền quyết định của Công Ty, có quyền tính phí
              đối với việc cấp quyền truy cập và sửa đổi Dữ Liệu Cá Nhân nêu trên nếu Công Ty cho là phù hợp.
            </p>
            <p>
              Tùy từng thời điểm, Công Ty có thể gửi email hoặc tin nhắn (<strong>"SMS"</strong>) đến số điện thoại di
              động của các Thành Viên và/hoặc Các Cá Nhân Liên Quan dựa trên Dữ Liệu Cá Nhân được cung cấp cho Công Ty.
              Công Ty không bảo đảm về việc các tin nhắn này là hoàn toàn an toàn. Trong mọi trường hợp, Công Ty không
              chịu trách nhiệm đối với bất kỳ thiệt hại nào phát sinh hoặc gánh chịu bởi các Thành Viên hoặc Các Cá Nhân
              Liên Quan liên quan đến hoặc phát sinh từ bất kỳ tin nhắn nào được gửi bởi Công Ty hoặc tới Công Ty.
            </p>
          </>
        ) : (
          <>
            <p>
              The privacy policy (<strong>"The privacy policy"</strong>) is an integral part of the Website terms of use
              governing the relationship between the Company and the user who accesses to the Company's website (
              <strong>"Website"</strong>) and/or a potential customer who is interest in the service provided by the
              Company (collectively referred to as the
              <strong>"Member"</strong> or the <strong>"User"</strong>) and should be read in conjunction with any of
              the terms and conditions entered into between the User and the Company.
            </p>
            <p>
              <strong>"Personal Data"</strong> in the Privacy Policy means data which enables an individual to be
              identified including, but not limited to, information such as names, contact details, addresses and other
              identification details of the User who access the Website or any of the services provided by the Company
              through the Website (<strong>"Services"</strong>) or any of the Services, signatures, answers to questions
              intended for security verification, emergency contact numbers or call back contact details provided or
              made available to us when any User accesses the Website.
            </p>
            <p>
              From time to time, the Company may collect Personal Data from (a) the User, (b) third parties, including
              User's beneficial owners, partners, directors, officers or authorized signatories, employees, customers,
              payors, payees, guarantors, other security providers and other natural persons related to that user (the "
              Relevant Individuals ") and/or (c) publicly available sources including credit bureaus, Personal Data
              about the Users, Relevant Individuals and related parties in connection with the access and use of the
              Website or any of the Services to provide more personalized service for our User. This may include:
            </p>
            <ol>
              <li>The use of cookies in the Website and in the provision of the Services;</li>
              <li>
                Collection of information in relation to the devices (including device operating system, hardware
                version, settings, file and software names and types and device identifiers, device location, connection
                information such as the name of the ISP or mobile operator to access the Website.
              </li>
            </ol>
            <p>Should the Member provide the Company any Personal Data of yourself or others:</p>
            <ol>
              <li>
                The Member represents and warrants to the Company that (x) each individual (including yourself) whose
                Personal Data has been provided to us, has been notified of the purposes for which data will be
                collected, processed, used or disclosed; and (y) such individual's consent for the collection,
                processing, use and disclosure of such Personal Data by SCP has been obtained and authorized by such
                individual;
              </li>
              <li>
                The Member shall notify us immediately upon becoming aware of any withdrawal by any individual of
                his/her consent to the collection, processing, use and/or disclosure by the Company of his/her Personal
                Data provided to the Company. The withdrawal of such consent may affect the services which the Company
                is able to provide to the Members, such individual and/or the corporate the Members represent (including
                possible termination of contractual relationship of the Members with us, subject to termination
                provisions relating to registered users and may be deemed a breach of representation and undertakings of
                the Member to us).
              </li>
              <li>
                The Member shall ensure at all times that any information provided (including any Personal Data) to us
                is correct, accurate and complete, and that any consent given in relation to Personal Data shall,
                subject to all applicable laws and regulations, survive death, incapacity, bankruptcy or insolvency of
                any such individual and the termination or expiration of any account in connection with the use of the
                Website.
              </li>
            </ol>
            <p>
              Personal Data submitted or collected by the Company may be used by the Company for any of its business
              purposes, including (the "Purposes"):
            </p>
            <ol>
              <li>
                To operate, administer any account and to provide Services to any User through the Website including,
                but not limited to, facilitating the transfer of funds, maintaining accurate "know your customer"
                information, to verify the identities or authorities of the User and/or their representatives who
                contact the Company or may be contacted by the Company, to carry out or respond to requests, questions
                or instructions from the User and/or their representatives, and to perform risk modeling or analysis;
              </li>
              <li>To facilitate transactions on the Website or in connection with the Services;</li>
              <li>
                To comply with the terms of use of the Website, the Privacy Policy and any other terms and conditions as
                may be stipulated by the Company from time to time and at any time;
              </li>
              <li>
                To verify the identity or authority of the User and/or representatives of the User and/or to pursuant to
                current security procedures or internal procedures of the Company;
              </li>
              <li>
                In the assessment and evaluation of viability, credibility, suitability, eligibility and identity of the
                User in connection with the Services, and/or any other documents entered into or agreed to by such User;
              </li>
              <li>To carry out, monitor and analyze the Company' business;</li>
              <li>
                For the marketing, promotion or advertisement of products and services (including any other financial
                services) which the Company believes the Member may be interested in;
              </li>
              <li>As part of, or in connection with, any sale, merger, or similar change of the Company's business;</li>
              <li>
                For customer feedback and complaints or legal purposes in enforcing the Company's rights, drafting and
                reviewing of documents, procuring legal advice and dispute resolution;
              </li>
              <li>To comply with any applicable laws, rules, or regulations in any country;</li>
              <li>
                To detect, prevent and investigate fraud, crime, offenses, money-laundering, counter-terrorist financing
                and bribery; and
              </li>
              <li>Purposes reasonably related to the aforesaid.</li>
            </ol>
            <p>
              The Company may retain Personal Data for so long as one or more of the purpose for which it was collected
              remains valid, where required by regulation or law including other jurisdictions outside of Vietnam, or as
              required by its own record retention policies or own business purposes. The Company may from time to time
              also disclose Personal Data for any of the Purposes to the Company's shareholders, directors, officers and
              employees and the following parties (whether inside or outside of Vietnam):
            </p>
            <ol>
              <li>
                Any agent, contractor, or third party service provider who provides banking, remittance, administrative,
                mailing, telecommunications, call centers, business process, travel, visa, knowledge management, human
                resource, data processing, information technology, computer, payment, debt collection, credit reference
                checks or securities clearing or risk modeling or analysis or other services to SCP in connection with
                the business operations of the Company and/or the operation of the Website or any of the Services;
              </li>
              <li>
                Any person or entity employed by or on behalf of the Company or is a part of or related to any group of
                companies of which the Company forms part or is affiliated to;
              </li>
              <li>
                Any person or entity to whom the Company is under an obligation or otherwise required to make disclosure
                pursuant to any applicable laws and regulations, including disclosure to courts, tribunals, and/or
                legal, regulatory, tax and government authorities and stock exchanges;
              </li>
              <li>
                Any party giving or proposing to give a guarantee or third-party security to guarantee or secure the
                obligations of any User;
              </li>
              <li>Any party for purposes of conducting checks with the Do Not Call Registry;</li>
              <li>
                Any party for purposes of meeting or complying with the Company's internal policies and procedures and
                any applicable rules, laws, regulations, codes of practice or guidelines, orders or requests issued by
                any court, legal or regulatory bodies including other jurisdictions outside of Vietnam (including but
                not limited to disclosures to regulatory bodies, conducting audit checks or any investigations);
              </li>
              <li>
                Any actual or proposed assignee of SCP or transferee of SCP' rights in respect of all or any part of the
                assets or business of SCP; and
              </li>
              <li>
                Any credit bureau as well as the members of such credit bureau and other relevant third parties in
                connection with the use of the Website as required by law or regulation.
              </li>
            </ol>
            <p>
              Without prejudice to the foregoing, the Company may disclose Personal Data to any person or entity to whom
              the Company is under an obligation or otherwise required to make disclosure pursuant to any applicable
              laws and regulations, including disclosure to courts, tribunals, and/or legal, regulatory, tax and
              government authorities in Vietnam or otherwise.
            </p>
            <p>
              The Members may access or make corrections to any Personal Data of the Members held by the Company. To do
              so, please contact the Company at <strong>support@scp.com.vn</strong>. The Company reserves the right to
              charge the fees as it may, in its sole and absolute discretion, deem appropriate for the grant of such
              access and to correct any Personal Data.
            </p>
            <p>
              The Company may from time to time send the Members and/or the Relevant Individuals email messages or short
              message service (<strong>"SMS"</strong>) to mobile phones based on Personal Data provided to the Company.
              The Company does not guarantee that these messages will be completely secure. Under no circumstance will
              the Company be liable for any damages incurred or sustained by the Members or the Relevant Individuals in
              connection with or arising from any such messages transmitted by the Company or to the Company.
            </p>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
