import { t, Trans } from '@lingui/macro';
import { CheckOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import jwt_decode from 'jwt-decode';
import { RegisterLayout } from 'layouts';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'reducers/profile';
import { sha256 } from 'utils/common';
import { IS_EPAYZ } from 'env';
// import $ from "jquery";
const RegisterPage = () => {
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [openErr, setopenErr] = useState(false);
  const [err, setErr] = useState('');

  const { control, handleSubmit, getValues } = useForm({ mode: 'onChange' });
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [verified, setVerified] = useState(false);
  const [disInput, setDisInput] = useState(true);
  const [checkbox, setCheckbox] = useState(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');

  const decode: any = token && jwt_decode(token);

  fetch(`${process.env.REACT_APP_APP_API}/api/v1/users/${decode.payload.user.id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (!res.exist) {
        setopenErr(true);
      }
    })
    .catch(() => {
      setopenErr(true);
    });
    setTimeout(function(){
      // console.log($(`input[name="idCard"]`).length);
      setDisInput(false);
    }, 1000)
    

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      setLoading(true);
      fetch(`${process.env.REACT_APP_APP_API}/api/v1/users/${decode.payload.user.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          confirm_password: (values.confirmPassword),
          fullname: values.fullname,
          id_card: values.idCard,
          password: (values.password),
          phone: values.phone,
          position: values.position,
          username: values.username,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.error_message) alert(res.error_message);
          alert(t`Account has been activated successfully`);
          dispatch(signOut());
          window.location.href = '/';
        });
    })();
  };

  const handleClickCheck = () => {
    if (username.trim() === '') {
      return;
    }
    setLoadingCheck(true);
    setErr('');
    fetch(`${process.env.REACT_APP_APP_API}/api/v1/users/${decode.payload.user.id}/check?username=${username}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoadingCheck(false);
        if (res.exist) {
          setVerified(false);
          setErr(t`Username is existed`);
        } else {
          setVerified(true);
        }
      });
  };

  return (
    <RegisterLayout>
      <Container>
        <div className='text-xl font-medium'>
          <Trans>Enterprise Information</Trans>
        </div>
        <div className='mt-4 flex text-gray-600'>
          <Trans>Enterprise Name</Trans>:
          <div className='ml-4 text-gray-900 font-medium'>{decode.payload.enterprise.name}</div>
        </div>

        <div className='mt-2 flex text-gray-600'>
          <Trans>Address</Trans>:
          <div className='ml-4 text-gray-900 font-medium'>{decode.payload.enterprise.address}</div>
        </div>

        <div className='mt-2 flex text-gray-600'>
          <Trans>Tax Code</Trans>:
          <div className='ml-4 text-gray-900 font-medium'>{decode.payload.enterprise.tax_code}</div>
        </div>

        <div className='mt-2 flex text-gray-600'>
          <Trans>Legal Representative</Trans>:
          <div className='ml-4 text-gray-900 font-medium'>{decode.payload.enterprise.legal_representative}</div>
        </div>

        <div className='mt-4 text-xl font-medium'>
          <Trans>Account Information</Trans>
        </div>

        <Grid container columnSpacing={4} rowSpacing={3} className='mt-4'>
          <Grid item sm={6}>
            <Controller
              name='fullname'
              defaultValue={decode.payload.user.fullname}
              control={control}
              rules={{
                validate: (e) => {
                  if (!e || e.trim() == "") {
                    return t`Full name is required`;
                  }
                  return true;
                }
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Full Name`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item sm={6}>
            <Controller
              name='position'
              defaultValue={decode.payload.user.position}
              control={control}
              rules={{
                validate: (e) => {
                  if (!e || e.trim() == "") {
                    return t`Position is required`;
                  }
                  return true;
                }
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Position`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              name='username'
              defaultValue={decode.payload.user.username}
              control={control}
              // rules={{
              //   required: t`User Name is required`,
              //   minLength: {
              //     value: 5,
              //     message: t`User Name must contain at least 5 characters`,
              //   },
              // }}
              rules={{
                validate: (e) => {
                  if (!e || e.trim() == "") {
                    return t`User Name is required`;
                  }
                  else if (e.length < 5) {
                    return t`User Name must contain at least 5 characters`
                  }
                  return true;
                }
              }}
              render={({ field: { value, onChange: setValue }, fieldState: { invalid, error } }) => (
                <TextField
                  {...value}
                  onChange={(event) => {
                    setVerified(false);
                    setUsername(event.target.value);
                    setValue(event);
                  }}
                  required
                  label={t`User Name`}
                  fullWidth
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
            {err && <div className='mt-2 text-red-500'>{err}</div>}
          </Grid>
          <Grid item sm={2}>
            {verified ? (
              <CheckOutlined fontSize='large' color='success' />
            ) : (
              <LoadingButton loading={loadingCheck} variant='contained' onClick={handleClickCheck}>
                <Trans>Check</Trans>
              </LoadingButton>
            )}
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='idCard'
              defaultValue={decode.payload.user.id_card}
              control={control}
              // rules={{
              //   required: t`ID Card/Passport is required`,
              // }}
              rules={{
                validate: (e) => {
                  if (!e || e.trim() == "") {
                    return t`ID Card/Passport is required`;
                  }
                  return true;
                }
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`ID Card/Passport`}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disInput}
                />
              )}
            />
          </Grid>

          <Grid item sm={6}>
            <Controller
              name='password'
              control={control}
              // rules={{
              //   required: t`Password is required`,
              //   pattern: {
              //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              //     message: t`Password must contain 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character`,
              //   },
              // }}
              rules={{
                validate: (e) => {
                  if (!e || e.trim() == "") {
                    return t`ID Card/Passport is required`;
                  } else if (e.length < 8 
                    || !/[A-Z]/.test(e) 
                    || !/[0-9]/.test(e) 
                    || !/[*&!@#]/.test(e)) {
                    return t`Password must contain 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character`
                  }
                  return true;
                }
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  type='password'
                  required
                  label={t`Password`}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disInput}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='phone'
              defaultValue={decode.payload.user.phone}
              control={control}
              // rules={{ required: t`Phone is required` }}
              rules={{
                validate: (e) => {
                  if (!e || e.trim() == "") {
                    return t`Phone is required`;
                  }
                  return true;
                }
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth required label={t`Phone`} error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>

          <Grid item sm={6}>
            <Controller
              name='confirmPassword'
              control={control}
              rules={{
                required: t`Please Confirm Password`,
                validate: {
                  sameWithPassword: (v) => {
                    return v === getValues('password') || t`Confirm password is not same value with password`;
                  },
                },
              }}
              render={({ field, fieldState: { invalid, error } }) => {
                return (
                  <TextField
                    {...field}
                    fullWidth
                    type='password'
                    required
                    label={t`Confirm Password`}
                    error={invalid}
                    helperText={error?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='email'
              defaultValue={decode.payload.user.email}
              control={control}
              rules={{
                validate: (e) => {
                  if (!e.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    return t`Email is not valid`;
                  }
                  return true;
                }
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  disabled
                  {...field}
                  fullWidth
                  required
                  label={t`Email`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>

        <div className='flex items-center mt-3'>
          <Checkbox checked={checkbox} onChange={(e) => setCheckbox(e.target.checked)} />
          <div>
            <Trans>
              I have read and agreed to all{' '}
              <span className='text-blue-500 cursor-pointer' onClick={() => setOpenPopup(true)}>
                terms and conditions{' '}
              </span>
              of Platform
            </Trans>
          </div>
        </div>

        <div className='flex justify-center mt-4'>
          <LoadingButton
            loading={loading}
            variant='contained'
            className='w-40'
            disabled={!checkbox || !verified}
            onClick={handleClickSubmit}
          >
            <Trans>Verify</Trans>
          </LoadingButton>
        </div>
      </Container>
      <Dialog fullWidth maxWidth='md' open={openPopup} onClose={() => setOpenPopup(false)}>
        <DialogTitle>
          <Trans>Website Terms of use</Trans>
        </DialogTitle>
        <DialogContent>
          <div className='border' style={{ height: 'calc(-180px + 80vh)' }}>
            {IS_EPAYZ ? (
              <embed className='w-full h-full' src={require('assets/terms/Website Terms of use_EPAYZ.pdf')} />
            ) : (
              <embed className='w-full h-full' src={require('assets/terms/Website Terms of use_SCP.pdf')} />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <LoadingButton variant='outlined' onClick={() => setOpenPopup(false)}>
            <Trans>Cancel</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openErr}>
        <DialogTitle>
          <Trans>Expire Link</Trans>
        </DialogTitle>
        <DialogContent>
          <Trans>Your registration link has been expired. Please contact admin</Trans>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={false}
            variant='contained'
            onClick={() => {
              navigate('/');
            }}
          >
            <Trans>OK</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </RegisterLayout>
  );
};

export default RegisterPage;
