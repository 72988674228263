import { Trans } from '@lingui/macro';
import { Typography, TableContainer, Table, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { formatNumber } from 'utils/common';
import { useQuery } from 'react-query';
import { enterpriseService } from 'services';
import { t } from '@lingui/macro';
import { TableEmpty } from 'components';

const TopSale = () => {
  const { data: data1 } = useQuery(
    ['enterpriseService.getTopClientTrans', { type: "ENTERPRISE", size: 3 }],
    () => enterpriseService.getTopClientTrans({ type: "ENTERPRISE", size: 3 }),
  );
  const items1 = data1?.clients ?? [];
  const { data: data2 } = useQuery(
    ['enterpriseService.getTopClientTrans', { type: "EMPLOYEE", size: 3 }],
    () => enterpriseService.getTopClientTrans({ type: "EMPLOYEE", size: 3 }),
  );
  const items2 = data2?.clients ?? [];
  return (
    <div className='border rounded-lg mt-2 p-4'>
      <Typography variant='subtitle2'>
        <Trans>The business with the highest transaction value</Trans>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {items1?.map((item, index) => (
              <TableRow key={item.id}>
                {index === 0 ? (<TableCell className='bg-primary-dark text-white'>TOP {index + 1}</TableCell>) : (<TableCell className='bg-primary-light text-white'>TOP {index + 1}</TableCell>)}
                <TableCell>{item.name}</TableCell>
                <TableCell align='right'>{formatNumber(item.requestAmount)} (VND)</TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items1} />
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant='subtitle2'>
        <Trans>The employee with the highest transaction value</Trans>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {items2?.map((item, index) => (
              <TableRow key={item.id}>
                {index === 0 ? (<TableCell className='bg-primary-dark text-white'>TOP {index + 1}</TableCell>) : (<TableCell className='bg-primary-light text-white'>TOP {index + 1}</TableCell>)}
                <TableCell>{item.name}</TableCell>
                <TableCell align='right'>{formatNumber(item.requestAmount)} (VND)</TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items2} />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TopSale;
