import { AppContainer } from 'containers';
import { IS_EPAYZ, IS_SSO } from 'env';
import { LanguageProvider } from 'i18n';
import { default as jwtDecode } from 'jwt-decode';
import { PrivateLayout } from 'layouts';
import { useEffect, useState, useCallback } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from 'reducers';
import { epayzRoute, publicRoute, staticRoute } from 'routes';
import ApiService, {IApiService} from './services/ApiService';
import AuthService, {IAuthService} from './services/AuthService';
import AuthServiceMeta, {IAuthServiceMeta} from './services/AuthServiceMeta';
import { User } from 'oidc-client';
import { userService } from 'services';
import { openAlert } from 'reducers/notification';
import { t, Trans } from '@lingui/macro';
import { signIn, signOut } from 'reducers/profile';
import { Constants } from 'helpers/Constants';
import $ from "jquery";
//const authService: IAuthService = new AuthService();
const authServiceMeta: IAuthService = new AuthServiceMeta();

const App = () => {
  const [isReady, setIsReady] = useState(false);
  // const [api, setApi] = useState();
  // const [sessionStatus, changeSessionStatus] = useState('');
  // async function handleVisibilityChange(){
  //   if (document.hidden) {

  //   } else {
  //     var profile = localStorage.getItem("profile");
  //     if(window.location.pathname == "/"){
  //       if(profile){
  //           var profileParse = JSON.parse(profile ? profile : '');
  //           jwtDecode(profileParse.accessToken);
  //           store.dispatch(signIn(profileParse));      
  //       }
  //     }
  //     // else{
  //     //   if(!profile){
  //     //     window.location.href= "/";
  //     //   }
  //     // }
  //   }
  // }

  useEffect(() => {
    try {  
      if(IS_SSO)
      { 
        // setInterval(()=>{
        //   checkSession();
        // }, 1000)
        
        // document.addEventListener('visibilitychange', handleVisibilityChange);   
        const fetchData = async () => {
            var userResponse = await authServiceMeta.getUser();
            var profile = localStorage.getItem("profile");
            if (profile && userResponse) {
              var profileParse = JSON.parse(profile ? profile : '');
              jwtDecode(profileParse.accessToken);
              store.dispatch(signIn(profileParse)); 
            } else {
              if(process.env.REACT_APP_IS_EPAYZ){
                if(Constants.checkPassUrl(window.location.pathname) && window.location.pathname != "/"){
                  window.location.href = "/";
                }
              }else{
                window.location.href = "/";
              }
            }
        }
        if(Constants.checkPassUrl(window.location.pathname)){
          fetchData().catch(console.error);
        }
      }
      else
      {
        const profile = JSON.parse(localStorage.getItem('profile') ?? '');
        jwtDecode(profile.accessToken);
        store.dispatch(signIn(profile));
      }
    
    } catch(e) {
      console.log(e)
    } finally {
      setIsReady(true);
    }
  }, []);
  // useEffect(() => {
  //   if(sessionStatus == 'false'){
  //     if(Constants.checkPassUrl(window.location.pathname) && window.location.pathname != "/"){
  //       window.localStorage.clear();
  //       window.location.href="/";
  //     }
  //   }
  // }, [sessionStatus]);

  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <LanguageProvider>
          {/* <iframe id="CheckSession" src={Constants.stsAuthority + "html/checksession.html"} style={{ display:'none' }} onLoad={()=>{
              //document.getElementById("CheckSession").contentWindow.postMessage(Constants.)
              window.addEventListener(
                "message",
                (event) => {
                  if((event.origin + "/") == Constants.stsAuthority){
                    if(event.data == 'false' || event.data == 'true'){
                    changeSessionStatus(event.data);
                    }
                  }
                },
                false
              );
          }}></iframe> */}
          <AppContainer>
            {isReady && (
              <Routes>
                {Object.values(IS_EPAYZ ? epayzRoute : staticRoute).map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
                {Object.values(publicRoute).map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
                <Route path='/*' element={<PrivateLayout />} />
              </Routes>
            )}
          </AppContainer>
        </LanguageProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
};

export default App;
