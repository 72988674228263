import { Trans } from '@lingui/macro';
import { AppBar, Avatar, ListItemButton, Toolbar, Typography } from '@mui/material';
import ENFlag from 'assets/icons/flag-en.svg';
import VNFlag from 'assets/icons/flag-vn.svg';
import { Dropdown, DropdownIcon, HoverDropdown } from 'containers/Header';
import { IS_EPAYZ } from 'env';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { useDispatch } from 'react-redux';
import { setUserLocale } from 'reducers/user-settings';

const RegisterLayout = ({ children }: any) => {
  const dispatch = useDispatch();
  const userLocale = useActiveLocale();

  return (
    <div className='App'>
      <AppBar elevation={0}>
        <Toolbar className='flex items-center justify-between relative'>
          <div className='flex-1 font-bold text-center'>
            <Trans>ACCOUNT REGISTRATION</Trans>
          </div>
          <HoverDropdown className='absolute right-4'>
            <div className='flex items-center gap-1.5'>
              <img src={userLocale === 'vi-VN' ? VNFlag : ENFlag} alt='' width='20px' />
              {userLocale === 'vi-VN' ? 'Việt Nam' : 'English'}
              <DropdownIcon />
            </div>
            <Dropdown>
              <ListItemButton
                selected={userLocale === 'vi-VN'}
                className='flex gap-1.5'
                onClick={() => dispatch(setUserLocale('vi-VN'))}
              >
                <img src={VNFlag} alt='' width='20px' />
                Việt Nam
              </ListItemButton>
              <ListItemButton
                selected={userLocale === 'en-US'}
                className='flex gap-1.5'
                onClick={() => dispatch(setUserLocale('en-US'))}
              >
                <img src={ENFlag} alt='' width='20px' />
                English
              </ListItemButton>
            </Dropdown>
          </HoverDropdown>
        </Toolbar>
      </AppBar>
      <div
        className='min-h-screen py-16 px-10 flex items-center'
        style={{
          backgroundImage: IS_EPAYZ
            ? `linear-gradient(to right, #DC2626, #DC2626 40%, #FECACA)`
            : `linear-gradient(to right, #4B5563, #4B5563 40%, #E5E7EB)`,
        }}
      >
        <div className='flex flex-col items-center text-white p-20 mb-60'>
          <Avatar className='w-[120px] h-[120px]' />
          <Typography className='font-medium text-3xl my-6'>Xin chào!</Typography>
          <Typography className='text-center'>
            Chào mừng bạn đã gia nhập hệ thống giao dịch của {IS_EPAYZ ? 'EPZ' : 'SCP'}.
            <br />
            Vui lòng điền các thông tin yêu cầu bên đây để hoàn tất đăng ký.
          </Typography>
        </div>
        <div className='bg-white py-10 px-20 rounded-l-[40px]'>{children}</div>
      </div>
    </div>
  );
};

export default RegisterLayout;
