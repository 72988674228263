import { StaticHeader } from 'containers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import { LoginContent, LoginForm } from '.';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { IS_SSO } from 'env';
const LoginPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(profileSelector);
  const userLocale = useActiveLocale();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(privateRoute.home.path, { replace: true });
    }
  }, [navigate, isLoggedIn]);
  return (
    <div className='App'>
      { !IS_SSO &&
      <div>
        <StaticHeader />
        <div
          className='h-screen pt-20 flex'
          style={{
            backgroundImage: `url(${
              userLocale === 'vi-VN' ? require('assets/images/bg-login-vi.png') : require('assets/images/bg-login-en.png')
            })`,
          }}
        >
          <div className='w-full flex items-center pl-60 py-20'>
            <LoginForm />
          </div>
        </div>
        <LoginContent />
      </div>
      }
      { IS_SSO &&
      <div>
        <img src={require('assets/images/UntitledWhite.png')} style={{ width: '100%', height: 'calc(100vh)' }} />
      </div>
      }
    </div>
  );
};

export default LoginPage;
