import { Trans } from '@lingui/macro';
import { Container, Typography } from '@mui/material';
import { IS_EPAYZ } from 'env';
import { EpayzLayout, StaticLayout } from 'layouts';
import { useActiveLocale } from 'hooks/useActiveLocale';

const Layout = IS_EPAYZ ? EpayzLayout : StaticLayout;

const TermsOfUse = () => {
  const userLocale = useActiveLocale();
  return (
    <Layout>
      <Container className='py-20 notail'>
        <Typography className='font-bold text-3xl text-center mb-10'>
          <Trans>WEBSITE TERMS OF USE</Trans>
        </Typography>

        {IS_EPAYZ ? (
          userLocale === 'vi-VN' ? (
            <>
              <p>
                Quyền truy cập vào website do <strong>Công ty TNHH Nền tảng Số EPZ</strong>. ("Công Ty", "EPZ",
                "chúng tôi", "của chúng tôi" hoặc "chúng ta") ”) cấp phụ thuộc vào các điều khoản sử dụng và chính sách
                bảo mật đính kèm (<strong>"Các Điều Khoản Sử Dụng"</strong>). Vui lòng đọc kỹ Các Điều Khoản Sử Dụng
                này. Bằng việc truy cập website này, quý vị đồng ý bị ràng buộc bởi Các Điều Khoản Sử Dụng này. Nếu quý
                vị không chấp nhận bất kỳ điều khoản nào trong Các Điều Khoản Sử Dụng này, vui lòng ngừng truy cập vào
                website này ngay lập tức.
              </p>
              <p>
                Tùy từng thời điểm, chúng tôi có thể thay đổi Các Điều Khoản Sử Dụng này và đăng tải phiên bản sửa đổi
                trên website này, các phiên bản sửa đổi sẽ có hiệu lực ngay khi được đăng tải. Chúng tôi không có nghĩa
                vụ phải thông báo riêng cho quý vị về bất kỳ thay đổi nào đối với Các Điều Khoản Sử Dụng này. Việc quý
                vị tiếp tục truy cập website này sau khi Các Điều Khoản Sử Dụng sửa đổi đã có hiệu lực sẽ cấu thành sự
                chấp nhận vô điều kiện của quý vị đối với Các Điều Khoản Sử Dụng sửa đổi đó.
              </p>

              <ul>
                <li>
                  Tất cả quyền truy cập và sử dụng website này (bao gồm bất kỳ nội dung được gửi, bài đăng hoặc bất kỳ
                  hình thức nào khác của hoạt động truyền tải) sẽ được điều chỉnh bởi Các Điều Khoản Sử Dụng này. Bất kỳ
                  nội dung nào bị xem là bất hợp pháp theo quy định của pháp luật Việt Nam sẽ bị chúng tôi xóa bỏ tùy
                  từng thời điểm. Nếu quý vị chọn truy cập website này từ nơi khác ngoài lãnh thổ Việt Nam, quý vị sẽ
                  chịu trách nhiệm đối với việc tuân thủ pháp luật tại lãnh thổ mà quý vị lựa chọn truy cập.
                </li>

                <li>
                  Website và nội dung của website này, bao gồm nhưng không giới hạn bởi các dịch vụ, sản phẩm, thông
                  tin, dữ liệu, văn bản, đồ họa, âm thanh, video, liên kết (bởi bất kỳ bên thứ ba hoặc các bên khác nếu
                  có), quảng cáo hoặc các nội dung khác (“Nội Dung”) được cung cấp trên cở sở “như nó vốn có” và trên cơ
                  sở “như có sẵn”. Công Ty không đưa ra bất kỳ cam đoan, bảo đảm hoặc cam kết nào (tùy từng trường hợp),
                  dù rõ ràng hoặc ngụ ý, cho bất kỳ cá nhân nào dưới bất kỳ hình thức nào, bao gồm:
                </li>
                <ol>
                  <li>
                    Tính khả dụng của website này đối với bất kỳ mục đích xem hoặc bất kỳ mục đích nào khác được đặt ra
                    trong website này và sẽ không có sự gián đoạn hoặc lỗi đối với bất kỳ tính năng hoặc chức năng nào
                    của website này;
                  </li>
                  <li>
                    Liên quan đến bất kỳ tiêu đề nào liên quan đến website này và Nội Dung của website, mà khôngxâm phạm
                    đối với các quyền của bên thứ ba;
                  </li>
                  <li>
                    Về việc website này hoặc các máy chủ hỗ trợ website vận hành sẽ không có vi-rút, phần mềm độc hại
                    hoặc các thành phần gây hại hoặc mã độc khác, mã phá hoại hoặc làm hư hỏng, chương trình gián điệp
                    hoặc macro;
                  </li>
                  <li>
                    Về việc website này sẽ tương thích với tất cả phần mềm và phần cứng hoặc website sẽ đảm bảo việc
                    tương thích đó;
                  </li>
                  <li>
                    Về việc website này có khả năng hoặc chất lượng đáp ứng bất kỳ mục đích chung hoặc cụ thể nào;
                  </li>
                  <li>
                    Về việc thông tin trên website này là đầy đủ, đúng, chính xác, không gây nhầm lẫn, đáng tin cậy hoặc
                    hợp lệ;
                  </li>
                  <li>Về việc bất kỳ khiếm khuyết và/hoặc lỗi trên website này sẽ được sửa chữa ngay hoặc kịp thời;</li>
                  <li>
                    Về việc website này bao gồm thông tin chính xác về uy tín tín dụng, tình hình kinh doanh hoặc tài
                    chính của bất kỳ cá nhân/tổ chức nào; hoặc
                  </li>
                  <li>
                    Về việc website này bao gồm thông tin chính xác về bất kỳ nội dung, dịch vụ, sản phẩm, tài liệu hoặc
                    thông tin nào bao gồm trong bất kỳ liên kết hoặc website của bên thứ ba nào.
                  </li>
                </ol>

                <li>
                  Trong bất kỳ trường hợp nào, Công Ty (hoặc nhân viên, đại lý, giám đốc hoặc cổ đông của chúng tôi) sẽ
                  không chịu trách nhiệm đối với bất kỳ Nội Dung nào trên website này, bao gồm (không giới hạn) bất kỳ
                  lỗi hoặc thiếu sót nào trong bất kỳ Nội Dung nào, hoặc đối với bất kỳ tổn thất hoặc thiệt hại nào dưới
                  bất kỳ hình thức nào phát sinh do việc quý vị sử dụng website này hoặc bất kỳ Nội Dung nào.
                </li>
                <li>
                  Công Ty không nhận trách nhiệm cũng như không chấp nhận trách nhiệm đối với bất kỳ tổn thất hoặc thiệt
                  hại nào (dù trực tiếp hoặc gián tiếp), do bất kỳ nguyên nhân nào, là kết quả của hoặc phát sinh từ
                  việc quý vị không thể sử dụng hoặc truy cập website này và/hoặc bất kỳ Nội Dung nào.
                </li>
                <li>
                  Công Ty bảo lưu mọi quyền sửa đổi Nội Dung, các tính năng và chức năng của website này, tắt website
                  này và từ chối hoặc hạn chế quyền truy cập vào website này đối với bất kỳ cá nhân cụ thể nào, hoặc
                  chặn quyền truy cập từ một địa chỉ internet cụ thể vào website này, vào bất kỳ thời điểm nào và không
                  có bất kỳ thông báo trước nào, mà không cần bất kỳ lý do nào.
                </li>
                <li>
                  Tên, hình ảnh và logo (“Nhãn Hiệu”) định dạng Công Ty hoặc các bên thứ ba và các sản phẩm và dịch vụ
                  của các bên thứ ba phụ thuộc vào bản quyền, quyền đối với thiết kế và nhãn hiệu tương ứng của Công Ty
                  và/hoặc các bên thứ ba, và tất cả các quyền đối với Nhãn Hiệu được bảo lưu cụ thể bởi Công Ty hoặc các
                  bên thứ ba liên quan. Quý vị không có quyền hoặc không được cấp phép để tải xuống, sao chép hoặc sử
                  dụng bất kỳ Nhãn Hiệu nào hoặc tên của Công Ty cho dù sử dụng dưới dạng liên kết đến bất kỳ website
                  nào hoặc bằng cách khác, trừ khi có sự chấp thuận trước của Công Ty hoặc các bên thứ ba liên quan.
                </li>
                <li>
                  Website này có thể chứa các liên kết đến các website và các tài nguyên trực tuyến khác không phải do
                  Công Ty duy trì hoặc vận hành. Quý vị sẽ tự chịu trách nhiệm đối với các rủi ro khi sử dụng các
                  website và tài nguyên của bên thứ ba như vậy. Công Ty không chịu trách nhiệm và không xác nhận tính
                  khả dụng hoặc nội dung của các website hoặc tài nguyên của bên thứ ba đó và không cấu thành hoặc ngụ ý
                  bất kỳ sự tài trợ, khuyến nghị hoặc bất kỳ mối quan hệ nào khác giữa Công Ty và bên thứ ba đó. Công Ty
                  sẽ không chịu trách nhiệm đối với bất kỳ thiệt hại hoặc tổn thất nào (bao gồm bất kỳ vi-rút máy tính,
                  phần mềm độc hại hoặc các phần mềm hoặc quy trình tương tự nào), phát sinh từ các nội dung được cung
                  cấp trên các website hoặc tài nguyên của bên thứ ba đó.
                </li>
                <li>
                  Bất kỳ dữ liệu cá nhân nào được gửi cho Công Ty thông qua website này hoặc bằng cách khác có thể được
                  sử dụng cho mục đích kinh doanh của Công Ty.
                </li>
                <li>
                  Trong phạm vi tối đa được pháp luật hiện hành cho phép, Công Ty theo đây loại trừ hoàn toàn tất cả các
                  điều kiện, bảo hành và các điều khoản khác có thể được pháp luật hiện hành quy định trong Các Điều
                  Khoản Sử Dụng này.
                </li>
                <li>
                  Không ảnh hưởng đến bất kỳ giới hạn trách nhiệm nào khác do Công Ty quy định trong Các Điều Khoản Sử
                  Dụng này, mỗi Công ty, và tất cả các công ty con, công ty liên kết, giám đốc, nhân viên, đại lý, đối
                  tác và người lao động của Công Ty sẽ không chịu trách nhiệm đối với bất kỳ cá nhân nào, bao gồm, nhưng
                  không giới hạn bởi, bất kỳ người dùng nào của website này, đối với bất kỳ khoản bồi thường trực tiếp,
                  gián tiếp, do bị trừng phạt, ngẫu nhiên, đặc biệt hoặc do hệ quả nào, tổn thất, chi phí, trách nhiệm
                  pháp lý do bất kỳ nguyên nhân hành động hoặc bất kỳ thiệt hại nào, bao gồm, nhưng không giới hạn bởi,
                  thiệt hại do mất mát hoặc sử dụng dữ liệu, mất cơ hội, mất thiện chí, mất lợi nhuận (cho dù, nhưng
                  không giới hạn, doanh thu hoặc lợi nhuận dự kiến) hoặc tổn thất cho các bên thứ ba, phát sinh từ hoặc
                  bằng bất kỳ cách thức nào liên quan đến việc sử dụng, không thể sử dụng hoặc phụ thuộc vào bất kỳ nội
                  dung nào được cung cấp trên website này, cho dù dựa trên cơ sở hợp đồng, ngoài hợp đồng, trách nhiệm
                  pháp lý nghiêm trọng hoặc bằng cách khác, ngay cả khi Công Ty hoặc đại lý hoặc người lao động của Công
                  Ty đã được thông báo về khả năng xảy ra các thiệt hại hoặc tổn thất đó.
                </li>
                <li>
                  Không ảnh hưởng đến các điều khoản khác trong Các Điều Khoản Sử Dụng này, quý vị theo đây cam kết bồi
                  hoàn và bảo đảm mỗi bên trong EPZ, và tất cả các công ty con, công ty liên kết, giám đốc, nhân viên,
                  đại lý, đối tác và người tổn, khiếu nại, chi phí (bao gồm tất cả chi phí luật sư trên cơ sở bồi hoàn
                  toàn bộ), yêu cầu, trách nhiệm pháp lý, tổn thất (dù trực tiếp, gián tiếp hoặc do hệ quả) và thiệt hại
                  (dù là thiệt hại ngoài hợp đồng, thiệt hại trong hợp đồng hoặc thiệt hại khác) phát sinh dưới bất kỳ
                  hình thức nào, bao gồm nhưng không giới hạn bởi các khiếu nại của bên thứ ba, các hình phạt và khiếu
                  nại về hành vi bôi nhọ của cơ quan quản lý, xâm phạm quyền sở hữu trí tuệ, tử vong, thương tích cơ
                  thể, sử dụng máy tính không phù hợp, truy cập trái phép hoặc bất hợp pháp vào máy tính (bao gồm nhưng
                  không giới hạn bởi hành vi tấn công máy tính), thiệt hại tài sản hoặc tổn thất về tiền mà các bên nêu
                  trên có thể phải chịu, gánh chịu hoặc phải thanh toán phát sinh từ, hoặc liên quan đến, hoặc theo việc
                  quý vị truy cập vào và/hoặc sử dụng website này, cho dù việc truy cập hoặc sử dụng đó có được phép hay
                  không hoặc do bất kỳ hành động hoặc không hành động nào của các bên nêu trên, việc quý vị vi phạm Các
                  Điều Khoản Sử Dụng này, vi phạm bất kỳ quyền nào của cá nhân hoặc tổ chức khác hoặc việc quý vị vi
                  phạm bất kỳ yêu cầu, nghĩa vụ theo luật nào hoặc các quy định pháp luật hiện hành.lao động của EPZ
                  được bồi hoàn vào bất kỳ thời điểm nào từ và chống lại tất cả các hành động, quy trình tố tụng, phí
                </li>

                <li>Bằng việc truy cập website này, quý vị đồng ý rằng quý vị sẽ không:</li>
                <ol>
                  <li>
                    Sử dụng website này theo bất kỳ cách thức nào mang tính bất hợp pháp, trái luật, lừa đảo, đe dọa,
                    quấy rối, thô tục, khiêu dâm, lạm dụng hoặc gây hại, hoặc bằng cách khác mang tính phản cảm, hoặc
                    liên quan đến bất kỳ mục đích hoặc hoạt động nào như vậy;
                  </li>
                  <li>
                    Sử dụng website này để bôi nhọ, lạm dụng, quấy rối, theo dõi, đe dọa hoặc bằng cách khác vi phạm các
                    quyền của các cá nhân khác hoặc Công Ty, bao gồm nhưng không giới hạn các quyền riêng tư hoặc quyền
                    công khai của các cá nhân khác;
                  </li>
                  <li>
                    Mạo danh bất kỳ cá nhân hoặc tổ chức nào, đưa ra tuyên bố không chính xác hoặc bằng cách khác xuyên
                    tạc mối liên kết của quý vị với bất kỳ cá nhân hoặc tổ chức nào liên quan đến website này hoặc trình
                    bày hoặc ngụ ý về việc EPZ ủng hộ bất kỳ tuyên bố nào quý vị đưa ra;
                  </li>
                  <li>
                    Can thiệp hoặc làm gián đoạn hoạt động của website này hoặc các máy chủ hoặc mạng được sử dụng để
                    duy trì website này hoặc vi phạm bất kỳ yêu cầu, quy trình, chính sách hoặc quy định nào của các
                    mạng đó;
                  </li>
                  <li>
                    Truyền tải hoặc bằng cách khác phát tán bất kỳ virus, sâu máy tính hoặc mã máy tính nào khác có hại
                    hoặc xâm phạm hoặc dự tính làm thiệt hại hoạt động của hoặc giám sát việc sử dụng bất kỳ phần cứng,
                    phần mềm hoặc thiết bị nào liên quan đến website này;
                  </li>
                  <li>Xóa bất kỳ thông báo về bản quyền, nhãn hiệu hoặc quyền sở hữu nào khác khỏi website này;</li>
                  <li>
                    Sử dụng bất kỳ thông tin nào được tìm thấy trên website này để vận động hoặc thu hút bất kỳ cá nhân
                    nào hoặc xúi giục bất kỳ cá nhân nào từ bỏ quan hệ lao động với, đầu tư, tìm kiếm nguồn tài trợ từ
                    hoặc bất kỳ mối quan hệ thương mại nào khác với EPZ hoặc website này; hoặc
                  </li>
                  <li>
                    Chèn đường dẫn liên kết đến website này hoặc bất kỳ phần nào của website này hoặc “sao chụp” bất kỳ
                    Nội dung nào trên bất kỳ máy chủ hoặc website nào khác.
                  </li>
                </ol>

                <li>
                  Cho dù có mẫu thuẫn với bất kỳ nội dung nào trên website này, việc quý vị đủ điều kiện nhận bất kỳ sản
                  phẩm hoặc dịch vụ nào trên website này phụ thuộc vào sự chấp thuận của EPZ.
                </li>
                <li>
                  Nếu bất kỳ điều khoản nào trong Các Điều Khoản Sử Dụng này hoặc một phần của điều khoản đó bị xem là
                  vô hiệu, bất hợp pháp hoặc không thể thực hiện được theo bất kỳ quy định pháp luật nào mà điều khoản
                  đó phụ thuộc vào, điều khoản đó hoặc một phần của điều khoản đó sẽ bị xem là vô hiệu, bất hợp pháp
                  hoặc không thể thực hiện được chỉ trong phạm vi quy định pháp luật đó.
                </li>
                <li>
                  Cá nhân không phải là một bên tham gia bất kỳ giao dịch nào giữa quý vị và EPZ sẽ không có quyền theo
                  Các Điều Khoản Sử Dụng này.
                </li>
                <li>
                  Các Điều Khoản Sử Dụng này được điều chỉnh bởi pháp luật Việt Nam và quý vị đệ trình không hủy ngang
                  tất cả các tranh chấp đến Trung Tâm Trọng Tài Quốc Tế Việt Nam theo nguyên tắc tố tụng trọng tài để
                  giải quyết hoặc xử lý bất kỳ tranh chấp nào có thể phát sinh từ hoặc liên quan đến Các Điều Khoản Sử
                  Dụng này. Số lượng trọng tài viên là ba. Ngôn ngữ sử dụng trong trọng tài là tiếng Anh. Liên quan đến
                  việc giải quyết tranh chấp nêu trên, quý vị từ bỏ không hủy ngang, trong phạm vi tối đa pháp luật hiện
                  hành cho phép, bất kỳ sự phản đối nào của quý vị vào thời điểm này hoặc trong tương lai về địa điểm
                  giải quyết bất kỳ tranh chấp nào như vậy tại bất kỳ tòa án nào hoặc việc bất kỳ tranh chấp nào như vậy
                  tại bất kỳ tòa án nào như vậy đã được giải quyết tại một tòa án không phù hợp.
                </li>
              </ul>

              <p>
                Website này sẽ không được hiển thị, truyền tải lại, phân phối lại, tiết lộ hoặc sao chép, hoặc dựa vào,
                toàn bộ hoặc một phần, bởi bất kỳ cá nhân nào vì bất kỳ mục đích nào mà không có sự đồng ý trước của
                Công Ty. Tùy từng thời điểm, website này có thể dẫn chiếu đến một số tính năng nhất định của hoạt động
                kinh doanh mua bán nợ và tài chính nhưng không vì mục đích mô tả đầy đủ tất cả các điều khoản quan trọng
                hoặc các điều khoản có thể khác với các điều khoản được tóm tắt trên website này. Theo đó, website này
                và Nội Dung hoàn toàn đủ điều kiện theo các tài liệu chi tiết hơn liên quan đến bất kỳ hoạt động tài
                chính thích hợp nào. Các thông tin trên website này dựa trên một số giả định và phân tích thông tin được
                cung cấp vào ngày tải lên Nội Dung và phản ánh các điều kiện ưu tiên áp dụng và các quan điểm của Công
                Ty vào ngày đó, tất cả các thông tin đó đều có thể được thay đổi vào bất kỳ thời điểm nào mà không cần
                thông báo trước. Mặc dù Công Ty đã lưu tâm để bảo đảm tính chính xác của thông tin trên website này,
                Công Ty không đưa ra bất kỳ cam đoan hoặc bảo đảm dưới bất kỳ hình thức nào, dù rõ ràng, ngụ ý hay theo
                quy định của pháp luật, và sẽ không chịu trách nhiệm về tính chính xác hoặc đầy đủ của thông tin.
              </p>
              <p>
                Website này và Nội Dung của website chỉ bao gồm các thông tin chung và không cấu thành, và sẽ không được
                xem là cấu thành, chào bán, chào mời, tư vấn hoặc khuyến nghị về việc mua hoặc bán bất kỳ sản phẩm tài
                chính nào vào bất kỳ công ty nào, hoặc cấp hoặc bảo đảm bất kỳ khoản tài trợ nào. Website này không được
                xem là trang tư vấn về kế toán, pháp lý, quy định pháp luật, thuế, tài chính hoặc các nội dung tư vấn
                khác. Hoạt động đầu tư không phải là nghĩa vụ của, đặt cọc, hoặc được bảo đảm hoặc bảo hiểm bởi Công Ty,
                hoặc bất kỳ công ty con, công ty liên kết, chi nhánh hoặc bên phân phối nào của Công Ty.
              </p>
            </>
          ) : (
            <>
              <p>
                Access to the website is granted by <strong>EPZ Digital Platform Co., Ltd</strong>. ("the Company", "EPZ",
                "we", "our" or "us") subject to these terms of use and our privacy policy attached hereto. (
                <strong>"Terms of Use"</strong>). Please read these Terms of Use carefully. By accessing this website,
                you agree to be bound by these Terms of Use. If you do not accept any of these Terms of Use, please
                discontinue your access to this website immediately.
              </p>
              <p>
                We may make changes to these Terms of Use from time to time and post a revised version on this website,
                which shall be effective immediately upon such posting. We are under no obligation to separately inform
                you of any revision to these Terms of Use. Your continued access of this website after the revised Terms
                of Use have taken effect will constitute your unconditional acceptance of such revised Terms of Use.
              </p>

              <ul>
                <li>
                  All access to and use of this website (including any submission, posting or any other form of
                  transmission activity) shall be governed by these Terms of Use. Any contents which may be deemed
                  illegal or unlawful under the laws of Vietnam shall be deleted by us from time to time. If you choose
                  to access this website from elsewhere other than Vietnam, you are responsible for compliance with
                  local laws in the territory of such access.
                </li>

                <li>
                  This website and its contents, including but not limited to services, products, information, data,
                  text, graphics, audio, video, links (by any third party or otherwise if any), advertisements or other
                  items ("Content") are provided on an "as is" basis and on an "as available" basis. The Company gives
                  no representation, warranty or undertaking (as the case may be), express or implied, to anyone of any
                  kind, including:
                </li>
                <ol>
                  <li>
                    The availability of this website for any viewing or other purpose set forth in this website and that
                    there will be no interruption or error in any of this website's feature or function;
                  </li>
                  <li>
                    In relation to any title in connection with this website and its Content, that there will be no
                    infringement of third-party rights;
                  </li>
                  <li>
                    That this website or the servers that make it available will be free of viruses, malware or other
                    harmful components or other malicious, destructive or corrupting code, agent program or macros;
                  </li>
                  <li>
                    That this website will be compatible with all software and hardware or that it will secure such
                    compatibility;
                  </li>
                  <li>
                    That this website is satisfactory to the fitness or quality for any general or particular purpose;
                  </li>
                  <li>
                    That the information on this website is complete, true, accurate, non-misleading, reliable or valid;
                  </li>
                  <li>That any defects and/or errors on this website will be corrected immediately or promptly;</li>
                  <li>
                    That this website includes accurate information about the credit worthiness, business or financial
                    situation of any persons/organizations; or
                  </li>
                  <li>
                    That this website includes accurate information about any content, service, product, material or
                    information contained in any third-party link or website.
                  </li>
                </ol>

                <li>
                  Under no circumstances will the Company (or our employees, agents, directors or shareholders) be
                  liable for any Content on this website, including (without limitation) any errors or omissions in any
                  Content, or for any loss or damage of any kind incurred as a result of your use of this website or any
                  Content.
                </li>
                <li>
                  The Company neither assumes nor accepts liability for any loss or damage (whether direct or indirect),
                  however caused as a result of or arising from your use or inability to use or access of this website
                  and/or any Content
                </li>
                <li>
                  The Company reserves all rights to modify the Content, features and functionality of this website, to
                  shut down this website and to deny or restrict access to this website from any particular person, or
                  to block access from a particular internet address to this website, at any time and without any prior
                  notice, without ascribing any reasons whatsoever.
                </li>
                <li>
                  The names, images and logos ("Marks") identifying the Company or third parties and their products and
                  services are subject to copyright, design rights and trademarks respectively, of the Company and/or
                  third parties, and all rights to the Marks are expressly reserved by the Company or the relevant third
                  parties. You shall have no right or license to download, reproduce or use any such Marks or the name
                  of the Company whether for use as a link to any website or otherwise, except with the prior approval
                  of the Company or the relevant third parties.
                </li>
                <li>
                  This website may contain links to other websites and online resources which are not maintained or
                  operated by the Company. The use of such third-party websites and resources is at your own risk. The
                  Company is not responsible for and does not endorse the availability or contents of such third-party
                  websites or resources and does not constitute or imply any sponsorship, recommendation or any other
                  relationship between the Company and such third party. The Company shall not be liable for any damages
                  or loss (including any computer viruses, malwares or similar items or processes), arising from the
                  availability of contents of those third-party websites or resources.
                </li>
                <li>
                  Any personal data submitted to the Company whether through this website or otherwise may be used for
                  the Company's business purpose.
                </li>
                <li>
                  To the maximum extent permitted by applicable laws, the Company hereby expressly excludes all
                  conditions, warranties and other terms that might otherwise be implied by applicable laws into these
                  Terms of Use.
                </li>
                <li>
                  Without prejudice to any other limitation of liability by the Company provided herein, each of the
                  Company, and all of its subsidiaries, affiliated companies, directors, officers, agents, partners and
                  employees shall not be liable to any person, including, but not limited to, any users of this website,
                  for any direct, indirect, punitive, incidental, special, consequential damages, losses, expenses,
                  liabilities under any causes of action or any damages whatsoever, including, without limitation,
                  damages for loss of use or data, loss of opportunity, loss of goodwill, loss of profits (whether, but
                  not limited to, revenue or anticipated profits) or losses to third parties, arising out of or in any
                  way connected with any use of, inability to use or reliance on any provided on, this website, whether
                  based on contract, tort, strict liability or otherwise, even if the Company or its agents or employees
                  had been advised of the possibility of such damages or losses.
                </li>
                <li>
                  Without prejudice to the other provisions herein, you hereby undertake to indemnify and keep each of
                  EPZ, and all of its subsidiaries, affiliated companies, directors, officers, agents, partners and
                  employees indemnified at all times from and against all actions, proceedings, costs, claims, expenses
                  (including all legal costs on a full indemnity basis), demands, liabilities, losses (whether direct,
                  indirect or consequential) and damages (whether in tort, contract or otherwise) whatsoever and
                  howsoever arising, including without limitation claims made by third parties, regulatory penalties and
                  claims for defamation, infringement of intellectual property rights, death, bodily injury, wrongful
                  use of computers, unauthorized or illegal access to computers (including but not limited to hacking),
                  property damage or pecuniary losses which they may sustain, incur, suffer or pay arising out of, in
                  connection with or pursuant to the access to and/or the use of this website by you, whether or not
                  such access or use was authorized or whether it was due to any act or omission on its part, the breach
                  of these Terms of Use by you, the violation by you of any rights of another person or entity or the
                  breach by you of any statutory requirement, duty or applicable laws.
                </li>

                <li>By accessing this website, you agree that you will not:</li>
                <ol>
                  <li>
                    Use this website in any way which is unlawful, illegal, fraudulent, threatening, harassing, vulgar,
                    obscene, abusive or harmful, or otherwise objectionable, or in connection with any such purpose or
                    activity;
                  </li>
                  <li>
                    Use this website to defame, abuse, harass, stalk, threaten or otherwise violate the rights of others
                    or the Company, including without limitation others' privacy rights or rights of publicity;
                  </li>
                  <li>
                    Impersonate any person or entity, falsely state or otherwise misrepresent your affiliation with any
                    person or entity in connection with this website or express or imply that EPZ endorses any statement
                    you make;
                  </li>
                  <li>
                    Interfere with or disrupt the operation of this website or the servers or networks used to make this
                    website available or violate any requirements, procedures, policies or regulations of such networks;
                  </li>
                  <li>
                    Transmit or otherwise make available in connection with this website any virus, worm or other
                    computer code that is harmful or invasive or may or is intended to damage the operation of, or to
                    monitor the use of, any hardware, software or equipment;
                  </li>
                  <li>Remove any copyright, trade mark or other proprietary rights notice from this website;</li>
                  <li>
                    Use any information found on this website to canvass or solicit any person or entice any person away
                    from the employment of, investment with, seeking funding from or any other commercial relationship
                    with EPZ or this website; or
                  </li>
                  <li>
                    Insert a hyperlink to this website or any part thereof or "mirror" any Content on any other server,
                    website or web page.
                  </li>
                </ol>

                <li>
                  Notwithstanding anything to the contrary in this website, your eligibility for any product or service
                  on this website is subject to acceptance by EPZ.
                </li>
                <li>
                  If any provision of these Terms of Use or part thereof is rendered void, illegal or unenforceable by
                  any legislation to which it is subject, it shall be rendered void, illegal or unenforceable to that
                  extent and no further.
                </li>
                <li>
                  A person not a party to any transaction between you and SCP shall have no right under these Terms of
                  Use.
                </li>
                <li>
                  These Terms of Use are governed by the laws of Vietnam and you irrevocably submit all disputes to the
                  Vietnam International Arbitration Center in accordance with its rules of arbitration in order to
                  settle or resolve any dispute which may arise out of or in connection with these Terms of Use. The
                  number of arbitrators shall be three. The language of the arbitration shall be English. In connection
                  therewith, you irrevocably waive, to the fullest extent permitted by applicable laws, any objection,
                  that you may now or hereafter have, to the laying of the venue of any such dispute in any such court
                  or that any such dispute brought in any such court has been brought in an inconvenient forum.
                </li>
              </ul>

              <p>
                This website shall not be shown, re-transmitted, re-distributed, disclosed or copied, or relied upon, in
                whole or in part, by any person for whatever purpose without the prior consent of the Company. This
                website may refer to certain features of debt trading business and financing from time to time but does
                not purport to contain a complete description of all material terms or of the terms which may differ
                from those summarized in this website. Accordingly, this website and the Content is qualified in its
                entirety by the more detailed documentation relating to any applicable financing. Information in this
                website is based upon certain assumptions and analysis of information available as at the date of the
                Content uploading and reflects prevailing conditions and the Company's views as of such date, all of
                which are subject to change at any time without notice. Although reasonable care has been taken to
                ensure the accuracy of information contained in this website, the Company makes no representation or
                warranty of any kind, express, implied or statutory, and shall not be responsible or liable for the
                accuracy or completeness of the information.
              </p>
              <p>
                This website and its Content are strictly for general information only and does not constitute, and
                shall not be deemed to constitute, an offer, solicitation, advice or recommendation to buy or sell any
                financing products in any company, or to grant or procure any financing. This website should not be
                construed as accounting, legal, regulatory, tax, financial or other advice. Investments are not
                obligations of, deposits in, or guaranteed or insured by the Company, or any of its subsidiary,
                associate, affiliate or distributors.
              </p>
            </>
          )
        ) : userLocale === 'vi-VN' ? (
          <>
            <p>
              Quyền truy cập vào website do <strong>Công ty TNHH Nền tảng Ứng lương Công nghệ SCP</strong>. ("Công Ty",
              "SCP", "chúng tôi", "của chúng tôi" hoặc "chúng ta") ”) cấp phụ thuộc vào các điều khoản sử dụng và chính
              sách bảo mật đính kèm (<strong>"Các Điều Khoản Sử Dụng"</strong>). Vui lòng đọc kỹ Các Điều Khoản Sử Dụng
              này. Bằng việc truy cập website này, quý vị đồng ý bị ràng buộc bởi Các Điều Khoản Sử Dụng này. Nếu quý vị
              không chấp nhận bất kỳ điều khoản nào trong Các Điều Khoản Sử Dụng này, vui lòng ngừng truy cập vào
              website này ngay lập tức.
            </p>
            <p>
              Tùy từng thời điểm, chúng tôi có thể thay đổi Các Điều Khoản Sử Dụng này và đăng tải phiên bản sửa đổi
              trên website này, các phiên bản sửa đổi sẽ có hiệu lực ngay khi được đăng tải. Chúng tôi không có nghĩa vụ
              phải thông báo riêng cho quý vị về bất kỳ thay đổi nào đối với Các Điều Khoản Sử Dụng này. Việc quý vị
              tiếp tục truy cập website này sau khi Các Điều Khoản Sử Dụng sửa đổi đã có hiệu lực sẽ cấu thành sự chấp
              nhận vô điều kiện của quý vị đối với Các Điều Khoản Sử Dụng sửa đổi đó.
            </p>

            <ul>
              <li>
                Tất cả quyền truy cập và sử dụng website này (bao gồm bất kỳ nội dung được gửi, bài đăng hoặc bất kỳ
                hình thức nào khác của hoạt động truyền tải) sẽ được điều chỉnh bởi Các Điều Khoản Sử Dụng này. Bất kỳ
                nội dung nào bị xem là bất hợp pháp theo quy định của pháp luật Việt Nam sẽ bị chúng tôi xóa bỏ tùy từng
                thời điểm. Nếu quý vị chọn truy cập website này từ nơi khác ngoài lãnh thổ Việt Nam, quý vị sẽ chịu
                trách nhiệm đối với việc tuân thủ pháp luật tại lãnh thổ mà quý vị lựa chọn truy cập.
              </li>

              <li>
                Website và nội dung của website này, bao gồm nhưng không giới hạn bởi các dịch vụ, sản phẩm, thông tin,
                dữ liệu, văn bản, đồ họa, âm thanh, video, liên kết (bởi bất kỳ bên thứ ba hoặc các bên khác nếu có),
                quảng cáo hoặc các nội dung khác (“Nội Dung”) được cung cấp trên cở sở “như nó vốn có” và trên cơ sở
                “như có sẵn”. Công Ty không đưa ra bất kỳ cam đoan, bảo đảm hoặc cam kết nào (tùy từng trường hợp), dù
                rõ ràng hoặc ngụ ý, cho bất kỳ cá nhân nào dưới bất kỳ hình thức nào, bao gồm:
              </li>
              <ol>
                <li>
                  Tính khả dụng của website này đối với bất kỳ mục đích xem hoặc bất kỳ mục đích nào khác được đặt ra
                  trong website này và sẽ không có sự gián đoạn hoặc lỗi đối với bất kỳ tính năng hoặc chức năng nào của
                  website này;
                </li>
                <li>
                  Liên quan đến bất kỳ tiêu đề nào liên quan đến website này và Nội Dung của website, mà khôngxâm phạm
                  đối với các quyền của bên thứ ba;
                </li>
                <li>
                  Về việc website này hoặc các máy chủ hỗ trợ website vận hành sẽ không có vi-rút, phần mềm độc hại hoặc
                  các thành phần gây hại hoặc mã độc khác, mã phá hoại hoặc làm hư hỏng, chương trình gián điệp hoặc
                  macro;
                </li>
                <li>
                  Về việc website này sẽ tương thích với tất cả phần mềm và phần cứng hoặc website sẽ đảm bảo việc tương
                  thích đó;
                </li>
                <li>Về việc website này có khả năng hoặc chất lượng đáp ứng bất kỳ mục đích chung hoặc cụ thể nào;</li>
                <li>
                  Về việc thông tin trên website này là đầy đủ, đúng, chính xác, không gây nhầm lẫn, đáng tin cậy hoặc
                  hợp lệ;
                </li>
                <li>Về việc bất kỳ khiếm khuyết và/hoặc lỗi trên website này sẽ được sửa chữa ngay hoặc kịp thời;</li>
                <li>
                  Về việc website này bao gồm thông tin chính xác về uy tín tín dụng, tình hình kinh doanh hoặc tài
                  chính của bất kỳ cá nhân/tổ chức nào; hoặc
                </li>
                <li>
                  Về việc website này bao gồm thông tin chính xác về bất kỳ nội dung, dịch vụ, sản phẩm, tài liệu hoặc
                  thông tin nào bao gồm trong bất kỳ liên kết hoặc website của bên thứ ba nào.
                </li>
              </ol>

              <li>
                Trong bất kỳ trường hợp nào, Công Ty (hoặc nhân viên, đại lý, giám đốc hoặc cổ đông của chúng tôi) sẽ
                không chịu trách nhiệm đối với bất kỳ Nội Dung nào trên website này, bao gồm (không giới hạn) bất kỳ lỗi
                hoặc thiếu sót nào trong bất kỳ Nội Dung nào, hoặc đối với bất kỳ tổn thất hoặc thiệt hại nào dưới bất
                kỳ hình thức nào phát sinh do việc quý vị sử dụng website này hoặc bất kỳ Nội Dung nào.
              </li>
              <li>
                Công Ty không nhận trách nhiệm cũng như không chấp nhận trách nhiệm đối với bất kỳ tổn thất hoặc thiệt
                hại nào (dù trực tiếp hoặc gián tiếp), do bất kỳ nguyên nhân nào, là kết quả của hoặc phát sinh từ việc
                quý vị không thể sử dụng hoặc truy cập website này và/hoặc bất kỳ Nội Dung nào.
              </li>
              <li>
                Công Ty bảo lưu mọi quyền sửa đổi Nội Dung, các tính năng và chức năng của website này, tắt website này
                và từ chối hoặc hạn chế quyền truy cập vào website này đối với bất kỳ cá nhân cụ thể nào, hoặc chặn
                quyền truy cập từ một địa chỉ internet cụ thể vào website này, vào bất kỳ thời điểm nào và không có bất
                kỳ thông báo trước nào, mà không cần bất kỳ lý do nào.
              </li>
              <li>
                Tên, hình ảnh và logo (“Nhãn Hiệu”) định dạng Công Ty hoặc các bên thứ ba và các sản phẩm và dịch vụ của
                các bên thứ ba phụ thuộc vào bản quyền, quyền đối với thiết kế và nhãn hiệu tương ứng của Công Ty
                và/hoặc các bên thứ ba, và tất cả các quyền đối với Nhãn Hiệu được bảo lưu cụ thể bởi Công Ty hoặc các
                bên thứ ba liên quan. Quý vị không có quyền hoặc không được cấp phép để tải xuống, sao chép hoặc sử dụng
                bất kỳ Nhãn Hiệu nào hoặc tên của Công Ty cho dù sử dụng dưới dạng liên kết đến bất kỳ website nào hoặc
                bằng cách khác, trừ khi có sự chấp thuận trước của Công Ty hoặc các bên thứ ba liên quan.
              </li>
              <li>
                Website này có thể chứa các liên kết đến các website và các tài nguyên trực tuyến khác không phải do
                Công Ty duy trì hoặc vận hành. Quý vị sẽ tự chịu trách nhiệm đối với các rủi ro khi sử dụng các website
                và tài nguyên của bên thứ ba như vậy. Công Ty không chịu trách nhiệm và không xác nhận tính khả dụng
                hoặc nội dung của các website hoặc tài nguyên của bên thứ ba đó và không cấu thành hoặc ngụ ý bất kỳ sự
                tài trợ, khuyến nghị hoặc bất kỳ mối quan hệ nào khác giữa Công Ty và bên thứ ba đó. Công Ty sẽ không
                chịu trách nhiệm đối với bất kỳ thiệt hại hoặc tổn thất nào (bao gồm bất kỳ vi-rút máy tính, phần mềm
                độc hại hoặc các phần mềm hoặc quy trình tương tự nào), phát sinh từ các nội dung được cung cấp trên các
                website hoặc tài nguyên của bên thứ ba đó.
              </li>
              <li>
                Bất kỳ dữ liệu cá nhân nào được gửi cho Công Ty thông qua website này hoặc bằng cách khác có thể được sử
                dụng cho mục đích kinh doanh của Công Ty.
              </li>
              <li>
                Trong phạm vi tối đa được pháp luật hiện hành cho phép, Công Ty theo đây loại trừ hoàn toàn tất cả các
                điều kiện, bảo hành và các điều khoản khác có thể được pháp luật hiện hành quy định trong Các Điều Khoản
                Sử Dụng này.
              </li>
              <li>
                Không ảnh hưởng đến bất kỳ giới hạn trách nhiệm nào khác do Công Ty quy định trong Các Điều Khoản Sử
                Dụng này, mỗi Công ty, và tất cả các công ty con, công ty liên kết, giám đốc, nhân viên, đại lý, đối tác
                và người lao động của Công Ty sẽ không chịu trách nhiệm đối với bất kỳ cá nhân nào, bao gồm, nhưng không
                giới hạn bởi, bất kỳ người dùng nào của website này, đối với bất kỳ khoản bồi thường trực tiếp, gián
                tiếp, do bị trừng phạt, ngẫu nhiên, đặc biệt hoặc do hệ quả nào, tổn thất, chi phí, trách nhiệm pháp lý
                do bất kỳ nguyên nhân hành động hoặc bất kỳ thiệt hại nào, bao gồm, nhưng không giới hạn bởi, thiệt hại
                do mất mát hoặc sử dụng dữ liệu, mất cơ hội, mất thiện chí, mất lợi nhuận (cho dù, nhưng không giới hạn,
                doanh thu hoặc lợi nhuận dự kiến) hoặc tổn thất cho các bên thứ ba, phát sinh từ hoặc bằng bất kỳ cách
                thức nào liên quan đến việc sử dụng, không thể sử dụng hoặc phụ thuộc vào bất kỳ nội dung nào được cung
                cấp trên website này, cho dù dựa trên cơ sở hợp đồng, ngoài hợp đồng, trách nhiệm pháp lý nghiêm trọng
                hoặc bằng cách khác, ngay cả khi Công Ty hoặc đại lý hoặc người lao động của Công Ty đã được thông báo
                về khả năng xảy ra các thiệt hại hoặc tổn thất đó.
              </li>
              <li>
                Không ảnh hưởng đến các điều khoản khác trong Các Điều Khoản Sử Dụng này, quý vị theo đây cam kết bồi
                hoàn và bảo đảm mỗi bên trong SCP, và tất cả các công ty con, công ty liên kết, giám đốc, nhân viên, đại
                lý, đối tác và người tổn, khiếu nại, chi phí (bao gồm tất cả chi phí luật sư trên cơ sở bồi hoàn toàn
                bộ), yêu cầu, trách nhiệm pháp lý, tổn thất (dù trực tiếp, gián tiếp hoặc do hệ quả) và thiệt hại (dù là
                thiệt hại ngoài hợp đồng, thiệt hại trong hợp đồng hoặc thiệt hại khác) phát sinh dưới bất kỳ hình thức
                nào, bao gồm nhưng không giới hạn bởi các khiếu nại của bên thứ ba, các hình phạt và khiếu nại về hành
                vi bôi nhọ của cơ quan quản lý, xâm phạm quyền sở hữu trí tuệ, tử vong, thương tích cơ thể, sử dụng máy
                tính không phù hợp, truy cập trái phép hoặc bất hợp pháp vào máy tính (bao gồm nhưng không giới hạn bởi
                hành vi tấn công máy tính), thiệt hại tài sản hoặc tổn thất về tiền mà các bên nêu trên có thể phải
                chịu, gánh chịu hoặc phải thanh toán phát sinh từ, hoặc liên quan đến, hoặc theo việc quý vị truy cập
                vào và/hoặc sử dụng website này, cho dù việc truy cập hoặc sử dụng đó có được phép hay không hoặc do bất
                kỳ hành động hoặc không hành động nào của các bên nêu trên, việc quý vị vi phạm Các Điều Khoản Sử Dụng
                này, vi phạm bất kỳ quyền nào của cá nhân hoặc tổ chức khác hoặc việc quý vị vi phạm bất kỳ yêu cầu,
                nghĩa vụ theo luật nào hoặc các quy định pháp luật hiện hành.lao động của SCP được bồi hoàn vào bất kỳ
                thời điểm nào từ và chống lại tất cả các hành động, quy trình tố tụng, phí
              </li>

              <li>Bằng việc truy cập website này, quý vị đồng ý rằng quý vị sẽ không:</li>
              <ol>
                <li>
                  Sử dụng website này theo bất kỳ cách thức nào mang tính bất hợp pháp, trái luật, lừa đảo, đe dọa, quấy
                  rối, thô tục, khiêu dâm, lạm dụng hoặc gây hại, hoặc bằng cách khác mang tính phản cảm, hoặc liên quan
                  đến bất kỳ mục đích hoặc hoạt động nào như vậy;
                </li>
                <li>
                  Sử dụng website này để bôi nhọ, lạm dụng, quấy rối, theo dõi, đe dọa hoặc bằng cách khác vi phạm các
                  quyền của các cá nhân khác hoặc Công Ty, bao gồm nhưng không giới hạn các quyền riêng tư hoặc quyền
                  công khai của các cá nhân khác;
                </li>
                <li>
                  Mạo danh bất kỳ cá nhân hoặc tổ chức nào, đưa ra tuyên bố không chính xác hoặc bằng cách khác xuyên
                  tạc mối liên kết của quý vị với bất kỳ cá nhân hoặc tổ chức nào liên quan đến website này hoặc trình
                  bày hoặc ngụ ý về việc SCP ủng hộ bất kỳ tuyên bố nào quý vị đưa ra;
                </li>
                <li>
                  Can thiệp hoặc làm gián đoạn hoạt động của website này hoặc các máy chủ hoặc mạng được sử dụng để duy
                  trì website này hoặc vi phạm bất kỳ yêu cầu, quy trình, chính sách hoặc quy định nào của các mạng đó;
                </li>
                <li>
                  Truyền tải hoặc bằng cách khác phát tán bất kỳ virus, sâu máy tính hoặc mã máy tính nào khác có hại
                  hoặc xâm phạm hoặc dự tính làm thiệt hại hoạt động của hoặc giám sát việc sử dụng bất kỳ phần cứng,
                  phần mềm hoặc thiết bị nào liên quan đến website này;
                </li>
                <li>Xóa bất kỳ thông báo về bản quyền, nhãn hiệu hoặc quyền sở hữu nào khác khỏi website này;</li>
                <li>
                  Sử dụng bất kỳ thông tin nào được tìm thấy trên website này để vận động hoặc thu hút bất kỳ cá nhân
                  nào hoặc xúi giục bất kỳ cá nhân nào từ bỏ quan hệ lao động với, đầu tư, tìm kiếm nguồn tài trợ từ
                  hoặc bất kỳ mối quan hệ thương mại nào khác với SCP hoặc website này; hoặc
                </li>
                <li>
                  Chèn đường dẫn liên kết đến website này hoặc bất kỳ phần nào của website này hoặc “sao chụp” bất kỳ
                  Nội dung nào trên bất kỳ máy chủ hoặc website nào khác.
                </li>
              </ol>

              <li>
                Cho dù có mẫu thuẫn với bất kỳ nội dung nào trên website này, việc quý vị đủ điều kiện nhận bất kỳ sản
                phẩm hoặc dịch vụ nào trên website này phụ thuộc vào sự chấp thuận của SCP.
              </li>
              <li>
                Nếu bất kỳ điều khoản nào trong Các Điều Khoản Sử Dụng này hoặc một phần của điều khoản đó bị xem là vô
                hiệu, bất hợp pháp hoặc không thể thực hiện được theo bất kỳ quy định pháp luật nào mà điều khoản đó phụ
                thuộc vào, điều khoản đó hoặc một phần của điều khoản đó sẽ bị xem là vô hiệu, bất hợp pháp hoặc không
                thể thực hiện được chỉ trong phạm vi quy định pháp luật đó.
              </li>
              <li>
                Cá nhân không phải là một bên tham gia bất kỳ giao dịch nào giữa quý vị và SCP sẽ không có quyền theo
                Các Điều Khoản Sử Dụng này.
              </li>
              <li>
                Các Điều Khoản Sử Dụng này được điều chỉnh bởi pháp luật Việt Nam và quý vị đệ trình không hủy ngang tất
                cả các tranh chấp đến Trung Tâm Trọng Tài Quốc Tế Việt Nam theo nguyên tắc tố tụng trọng tài để giải
                quyết hoặc xử lý bất kỳ tranh chấp nào có thể phát sinh từ hoặc liên quan đến Các Điều Khoản Sử Dụng
                này. Số lượng trọng tài viên là ba. Ngôn ngữ sử dụng trong trọng tài là tiếng Anh. Liên quan đến việc
                giải quyết tranh chấp nêu trên, quý vị từ bỏ không hủy ngang, trong phạm vi tối đa pháp luật hiện hành
                cho phép, bất kỳ sự phản đối nào của quý vị vào thời điểm này hoặc trong tương lai về địa điểm giải
                quyết bất kỳ tranh chấp nào như vậy tại bất kỳ tòa án nào hoặc việc bất kỳ tranh chấp nào như vậy tại
                bất kỳ tòa án nào như vậy đã được giải quyết tại một tòa án không phù hợp.
              </li>
            </ul>

            <p>
              Website này sẽ không được hiển thị, truyền tải lại, phân phối lại, tiết lộ hoặc sao chép, hoặc dựa vào,
              toàn bộ hoặc một phần, bởi bất kỳ cá nhân nào vì bất kỳ mục đích nào mà không có sự đồng ý trước của Công
              Ty. Tùy từng thời điểm, website này có thể dẫn chiếu đến một số tính năng nhất định của hoạt động kinh
              doanh mua bán nợ và tài chính nhưng không vì mục đích mô tả đầy đủ tất cả các điều khoản quan trọng hoặc
              các điều khoản có thể khác với các điều khoản được tóm tắt trên website này. Theo đó, website này và Nội
              Dung hoàn toàn đủ điều kiện theo các tài liệu chi tiết hơn liên quan đến bất kỳ hoạt động tài chính thích
              hợp nào. Các thông tin trên website này dựa trên một số giả định và phân tích thông tin được cung cấp vào
              ngày tải lên Nội Dung và phản ánh các điều kiện ưu tiên áp dụng và các quan điểm của Công Ty vào ngày đó,
              tất cả các thông tin đó đều có thể được thay đổi vào bất kỳ thời điểm nào mà không cần thông báo trước.
              Mặc dù Công Ty đã lưu tâm để bảo đảm tính chính xác của thông tin trên website này, Công Ty không đưa ra
              bất kỳ cam đoan hoặc bảo đảm dưới bất kỳ hình thức nào, dù rõ ràng, ngụ ý hay theo quy định của pháp luật,
              và sẽ không chịu trách nhiệm về tính chính xác hoặc đầy đủ của thông tin.
            </p>
            <p>
              Website này và Nội Dung của website chỉ bao gồm các thông tin chung và không cấu thành, và sẽ không được
              xem là cấu thành, chào bán, chào mời, tư vấn hoặc khuyến nghị về việc mua hoặc bán bất kỳ sản phẩm tài
              chính nào vào bất kỳ công ty nào, hoặc cấp hoặc bảo đảm bất kỳ khoản tài trợ nào. Website này không được
              xem là trang tư vấn về kế toán, pháp lý, quy định pháp luật, thuế, tài chính hoặc các nội dung tư vấn
              khác. Hoạt động đầu tư không phải là nghĩa vụ của, đặt cọc, hoặc được bảo đảm hoặc bảo hiểm bởi Công Ty,
              hoặc bất kỳ công ty con, công ty liên kết, chi nhánh hoặc bên phân phối nào của Công Ty.
            </p>
          </>
        ) : (
          <>
            <p>
              Access to the website is granted by <strong>SCP Technology Salary Advance Platform Ltc</strong>. ("the
              Company", "SCP", "we", "our" or "us") subject to these terms of use and our privacy policy attached
              hereto. (<strong>"Terms of Use"</strong>). Please read these Terms of Use carefully. By accessing this
              website, you agree to be bound by these Terms of Use. If you do not accept any of these Terms of Use,
              please discontinue your access to this website immediately.
            </p>
            <p>
              We may make changes to these Terms of Use from time to time and post a revised version on this website,
              which shall be effective immediately upon such posting. We are under no obligation to separately inform
              you of any revision to these Terms of Use. Your continued access of this website after the revised Terms
              of Use have taken effect will constitute your unconditional acceptance of such revised Terms of Use.
            </p>

            <ul>
              <li>
                All access to and use of this website (including any submission, posting or any other form of
                transmission activity) shall be governed by these Terms of Use. Any contents which may be deemed illegal
                or unlawful under the laws of Vietnam shall be deleted by us from time to time. If you choose to access
                this website from elsewhere other than Vietnam, you are responsible for compliance with local laws in
                the territory of such access.
              </li>

              <li>
                This website and its contents, including but not limited to services, products, information, data, text,
                graphics, audio, video, links (by any third party or otherwise if any), advertisements or other items
                ("Content") are provided on an "as is" basis and on an "as available" basis. The Company gives no
                representation, warranty or undertaking (as the case may be), express or implied, to anyone of any kind,
                including:
              </li>
              <ol>
                <li>
                  The availability of this website for any viewing or other purpose set forth in this website and that
                  there will be no interruption or error in any of this website's feature or function;
                </li>
                <li>
                  In relation to any title in connection with this website and its Content, that there will be no
                  infringement of third-party rights;
                </li>
                <li>
                  That this website or the servers that make it available will be free of viruses, malware or other
                  harmful components or other malicious, destructive or corrupting code, agent program or macros;
                </li>
                <li>
                  That this website will be compatible with all software and hardware or that it will secure such
                  compatibility;
                </li>
                <li>
                  That this website is satisfactory to the fitness or quality for any general or particular purpose;
                </li>
                <li>
                  That the information on this website is complete, true, accurate, non-misleading, reliable or valid;
                </li>
                <li>That any defects and/or errors on this website will be corrected immediately or promptly;</li>
                <li>
                  That this website includes accurate information about the credit worthiness, business or financial
                  situation of any persons/organizations; or
                </li>
                <li>
                  That this website includes accurate information about any content, service, product, material or
                  information contained in any third-party link or website.
                </li>
              </ol>

              <li>
                Under no circumstances will the Company (or our employees, agents, directors or shareholders) be liable
                for any Content on this website, including (without limitation) any errors or omissions in any Content,
                or for any loss or damage of any kind incurred as a result of your use of this website or any Content.
              </li>
              <li>
                The Company neither assumes nor accepts liability for any loss or damage (whether direct or indirect),
                however caused as a result of or arising from your use or inability to use or access of this website
                and/or any Content
              </li>
              <li>
                The Company reserves all rights to modify the Content, features and functionality of this website, to
                shut down this website and to deny or restrict access to this website from any particular person, or to
                block access from a particular internet address to this website, at any time and without any prior
                notice, without ascribing any reasons whatsoever.
              </li>
              <li>
                The names, images and logos ("Marks") identifying the Company or third parties and their products and
                services are subject to copyright, design rights and trademarks respectively, of the Company and/or
                third parties, and all rights to the Marks are expressly reserved by the Company or the relevant third
                parties. You shall have no right or license to download, reproduce or use any such Marks or the name of
                the Company whether for use as a link to any website or otherwise, except with the prior approval of the
                Company or the relevant third parties.
              </li>
              <li>
                This website may contain links to other websites and online resources which are not maintained or
                operated by the Company. The use of such third-party websites and resources is at your own risk. The
                Company is not responsible for and does not endorse the availability or contents of such third-party
                websites or resources and does not constitute or imply any sponsorship, recommendation or any other
                relationship between the Company and such third party. The Company shall not be liable for any damages
                or loss (including any computer viruses, malwares or similar items or processes), arising from the
                availability of contents of those third-party websites or resources.
              </li>
              <li>
                Any personal data submitted to the Company whether through this website or otherwise may be used for the
                Company's business purpose.
              </li>
              <li>
                To the maximum extent permitted by applicable laws, the Company hereby expressly excludes all
                conditions, warranties and other terms that might otherwise be implied by applicable laws into these
                Terms of Use.
              </li>
              <li>
                Without prejudice to any other limitation of liability by the Company provided herein, each of the
                Company, and all of its subsidiaries, affiliated companies, directors, officers, agents, partners and
                employees shall not be liable to any person, including, but not limited to, any users of this website,
                for any direct, indirect, punitive, incidental, special, consequential damages, losses, expenses,
                liabilities under any causes of action or any damages whatsoever, including, without limitation, damages
                for loss of use or data, loss of opportunity, loss of goodwill, loss of profits (whether, but not
                limited to, revenue or anticipated profits) or losses to third parties, arising out of or in any way
                connected with any use of, inability to use or reliance on any provided on, this website, whether based
                on contract, tort, strict liability or otherwise, even if the Company or its agents or employees had
                been advised of the possibility of such damages or losses.
              </li>
              <li>
                Without prejudice to the other provisions herein, you hereby undertake to indemnify and keep each of
                SCP, and all of its subsidiaries, affiliated companies, directors, officers, agents, partners and
                employees indemnified at all times from and against all actions, proceedings, costs, claims, expenses
                (including all legal costs on a full indemnity basis), demands, liabilities, losses (whether direct,
                indirect or consequential) and damages (whether in tort, contract or otherwise) whatsoever and howsoever
                arising, including without limitation claims made by third parties, regulatory penalties and claims for
                defamation, infringement of intellectual property rights, death, bodily injury, wrongful use of
                computers, unauthorized or illegal access to computers (including but not limited to hacking), property
                damage or pecuniary losses which they may sustain, incur, suffer or pay arising out of, in connection
                with or pursuant to the access to and/or the use of this website by you, whether or not such access or
                use was authorized or whether it was due to any act or omission on its part, the breach of these Terms
                of Use by you, the violation by you of any rights of another person or entity or the breach by you of
                any statutory requirement, duty or applicable laws.
              </li>

              <li>By accessing this website, you agree that you will not:</li>
              <ol>
                <li>
                  Use this website in any way which is unlawful, illegal, fraudulent, threatening, harassing, vulgar,
                  obscene, abusive or harmful, or otherwise objectionable, or in connection with any such purpose or
                  activity;
                </li>
                <li>
                  Use this website to defame, abuse, harass, stalk, threaten or otherwise violate the rights of others
                  or the Company, including without limitation others' privacy rights or rights of publicity;
                </li>
                <li>
                  Impersonate any person or entity, falsely state or otherwise misrepresent your affiliation with any
                  person or entity in connection with this website or express or imply that SCP endorses any statement
                  you make;
                </li>
                <li>
                  Interfere with or disrupt the operation of this website or the servers or networks used to make this
                  website available or violate any requirements, procedures, policies or regulations of such networks;
                </li>
                <li>
                  Transmit or otherwise make available in connection with this website any virus, worm or other computer
                  code that is harmful or invasive or may or is intended to damage the operation of, or to monitor the
                  use of, any hardware, software or equipment;
                </li>
                <li>Remove any copyright, trade mark or other proprietary rights notice from this website;</li>
                <li>
                  Use any information found on this website to canvass or solicit any person or entice any person away
                  from the employment of, investment with, seeking funding from or any other commercial relationship
                  with SCP or this website; or
                </li>
                <li>
                  Insert a hyperlink to this website or any part thereof or "mirror" any Content on any other server,
                  website or web page.
                </li>
              </ol>

              <li>
                Notwithstanding anything to the contrary in this website, your eligibility for any product or service on
                this website is subject to acceptance by SCP.
              </li>
              <li>
                If any provision of these Terms of Use or part thereof is rendered void, illegal or unenforceable by any
                legislation to which it is subject, it shall be rendered void, illegal or unenforceable to that extent
                and no further.
              </li>
              <li>
                A person not a party to any transaction between you and SCP shall have no right under these Terms of
                Use.
              </li>
              <li>
                These Terms of Use are governed by the laws of Vietnam and you irrevocably submit all disputes to the
                Vietnam International Arbitration Center in accordance with its rules of arbitration in order to settle
                or resolve any dispute which may arise out of or in connection with these Terms of Use. The number of
                arbitrators shall be three. The language of the arbitration shall be English. In connection therewith,
                you irrevocably waive, to the fullest extent permitted by applicable laws, any objection, that you may
                now or hereafter have, to the laying of the venue of any such dispute in any such court or that any such
                dispute brought in any such court has been brought in an inconvenient forum.
              </li>
            </ul>

            <p>
              This website shall not be shown, re-transmitted, re-distributed, disclosed or copied, or relied upon, in
              whole or in part, by any person for whatever purpose without the prior consent of the Company. This
              website may refer to certain features of debt trading business and financing from time to time but does
              not purport to contain a complete description of all material terms or of the terms which may differ from
              those summarized in this website. Accordingly, this website and the Content is qualified in its entirety
              by the more detailed documentation relating to any applicable financing. Information in this website is
              based upon certain assumptions and analysis of information available as at the date of the Content
              uploading and reflects prevailing conditions and the Company's views as of such date, all of which are
              subject to change at any time without notice. Although reasonable care has been taken to ensure the
              accuracy of information contained in this website, the Company makes no representation or warranty of any
              kind, express, implied or statutory, and shall not be responsible or liable for the accuracy or
              completeness of the information.
            </p>
            <p>
              This website and its Content are strictly for general information only and does not constitute, and shall
              not be deemed to constitute, an offer, solicitation, advice or recommendation to buy or sell any financing
              products in any company, or to grant or procure any financing. This website should not be construed as
              accounting, legal, regulatory, tax, financial or other advice. Investments are not obligations of,
              deposits in, or guaranteed or insured by the Company, or any of its subsidiary, associate, affiliate or
              distributors.
            </p>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default TermsOfUse;
