import { Trans } from '@lingui/macro';
import { Paper, Typography, Grid } from '@mui/material';
import { TopSale, BusinessResult, PaymentSchedule } from 'views/Epayz/Dashboard/Main/components';

const EpayzDashboardMain = () => {
  return (
    <Paper>
      <div className='p-3'>
        <Grid container columnSpacing={2}>
          <Grid item sm={4}>
            <Typography variant='h4' className='text-center'>
              <Trans>Top Sale</Trans>
            </Typography>
            <TopSale/>
          </Grid>
          <Grid item sm={8}>
            <Typography variant='h4' className='text-center'>
              <Trans>Business Result</Trans>
            </Typography>
            <BusinessResult />
          </Grid>
        </Grid>
        <PaymentSchedule />
      </div>
    </Paper>
  );
};

export default EpayzDashboardMain;
