import {
  UserCreateType,
  UserPaginateType,
  UserParamsType,
  UserType,
  UserUpdatePasswordType,
  UserUpdateType,
  ProfileUpdateType,
} from 'types/User';
import { client } from './axios';

const getProfile = ({ getlink } : { getlink: boolean }): Promise<UserType> => client.get(`/api/v1/users/me?getlink=${getlink}`);
const register = ({ id, ...body }: UserUpdateType) => client.put(`/api/v1/users/${id}`, body);
const updateProfile = ({ ...body }: ProfileUpdateType) => client.put(`/api/v1/users/profile`, body);
const updatePassword = ({ ...body }: UserUpdatePasswordType) => client.put(`/api/v1/users/change-password`, body);

const fetchUsers = (params?: UserParamsType): Promise<UserPaginateType> => client.get(`/api/v1/users`, { params });
const createUser = (body: UserCreateType) => client.post(`/api/v1/users/register`, body);
const removeUser = ({ id }: { id: number }) => client.delete(`/api/v1/users/${id}`);
const updateStatus = ({ id, status }: { id: number; status: string }) =>
  client.put(`/api/v1/users/${id}/change`, { status });

const updateAssignmentStatus = ({ id, allowDs }: { id: number; allowDs: boolean }) =>
  client.put(`/api/v1/users/${id}/assign_ds`, { allowDs });

export default {
  getProfile,
  register,
  updateProfile,
  updatePassword,

  fetchUsers,
  createUser,
  removeUser,
  updateStatus,
  updateAssignmentStatus,
};
