import { BankAccountCreateType, BankAccountParamsType, BankAccountType } from 'types/BankAccount';
import { FileDataType } from 'types/Common';
import {
  EmployeeBankType,
  EmployeeMeetingType,
  EmployeePaginateType,
  EmployeeParamsType,
  EmployeeSummary,
  EmployeeType,
  EmployeeUpdateType,
  EmployeeUserAssign,
} from 'types/Employee';
import {
  AppraisalCreateType,
  AppraisalDataType,
  AppraisalHistoryPaginateType,
  AppraisalParamsType,
} from 'types/EmployeeAppraisal';
import {
  ChecklistDocCreateType,
  ChecklistDocPaginateType,
  ChecklistDocParamsType,
  ChecklistLegalPaginateType,
  ChecklistStatusCreateType,
  ChecklistStatusType,
} from 'types/EmployeeChecklist';
import {
  ChangeKeyType,
  ContractApproveType,
  ContractCreateType,
  ContractLastestType,
  ContractPaginateType,
  ContractParamsType,
  ContractRejectType,
  ContractType,
  GenerateContractParamsType,
} from 'types/EmployeeContract';
import { ReportCreateType, ReportPaginateType, ReportParamsType } from 'types/EmployeeReport';
import { WorkingPaginateType, WorkingParamsType } from 'types/EmployeeWorking';
import { ActiveEmployeesRequestType, ActiveEmployeesResponseType } from 'types/User';
import { client } from './axios';

const getEmployeeById = ({ id }: { id: number | string }): Promise<EmployeeType> =>
  client.get(`/api/v1/employees/${id}`);
const fetchEmployees = (params?: EmployeeParamsType): Promise<EmployeePaginateType> =>
  client.get(`/api/v1/employees`, { params });
const fetchStatuses = (): Promise<string[]> => client.get(`/api/v1/employees/statuses`);
const updateEmployee = ({ id, ...body }: EmployeeUpdateType) => client.put(`/api/v1/employees/${id}`, body);

const getEmployeeMeeting = (): Promise<EmployeeMeetingType[]> => client.get(`/api/v1/employees/meeting`);
const assignUserCrm = (body: EmployeeUserAssign) => client.post(`/api/v1/employees/assign-crm`, body);
const assignUserCa = (body: EmployeeUserAssign) => client.post(`/api/v1/employees/assign-ca`, body);

const fetchEmployeeReports = ({ _id, ...params }: ReportParamsType): Promise<ReportPaginateType> =>
  client.get(`/api/v1/employees/${_id}/report`, { params });
const createEmployeeReport = ({ _id, ...body }: ReportCreateType) =>
  client.post(`/api/v1/employees/${_id}/report`, body);

const fetchEmployeeWorkings = ({ _id, ...params }: WorkingParamsType): Promise<WorkingPaginateType> =>
  client.get(`/api/v1/employees/${_id}/working`, { params });
const getEmployeeBankAccount = ({ _id, ...params }: BankAccountParamsType): Promise<BankAccountType> =>
  client.get(`/api/v1/employees/${_id}/bank`, { params });
const updateEmployeeBankAccount = ({ _id, ...body }: BankAccountCreateType) =>
  client.post(`/api/v1/employees/${_id}/bank`, body);
const getEmployeeBankAccountWorking = ({ _id, enterpriseId }: BankAccountParamsType): Promise<EmployeeBankType> =>
  client.get(`/api/v1/employees/${_id}/bank/${enterpriseId}`);
const updateEmployeeBankAccountWorking = ({ _id, enterpriseId, ...body }: BankAccountCreateType) =>
  client.post(`/api/v1/employees/${_id}/bank/${enterpriseId}`, body);

const getEmployeeChecklistStatus = ({ _id }: { _id: string }): Promise<ChecklistStatusType> =>
  client.get(`/api/v1/employees/${_id}/legal`);
const createEnterpriseChecklistStatus = ({ _id, ...body }: ChecklistStatusCreateType) =>
  client.post(`/api/v1/employees/${_id}/legal`, body);

const fetchEmployeeChecklist = ({ _id }: { _id: string }): Promise<ChecklistLegalPaginateType> =>
  client.get(`/api/v1/employees/${_id}/legal-type`);
const fetchEmployeeChecklistDoc = ({ _id, ...params }: ChecklistDocParamsType): Promise<ChecklistDocPaginateType> =>
  client.get(`/api/v1/employees/${_id}/legal-doc`, { params });
const createEmployeeChecklistDoc = ({ _id, ...body }: ChecklistDocCreateType) =>
  client.post(`/api/v1/employees/${_id}/legal-doc`, body);
const confirmEmployeeChecklistContract = ({ _id, legalDocId }: { _id: string; legalDocId: number }) =>
  client.post(`/api/v1/employees/${_id}/legal-doc/${legalDocId}/confirm`);

const getAppraisalById = ({ id }: { id: number }): Promise<AppraisalDataType> =>
  client.get(`/api/v1/employees/${id}/appraisal-detail`);
const getEmployeeAppraisal = ({ _id }: { _id: string }): Promise<AppraisalDataType> =>
  client.get(`/api/v1/employees/${_id}/appraisal`);
const fetchEmployeeAppraisal = ({ _id, ...params }: AppraisalParamsType): Promise<AppraisalHistoryPaginateType> =>
  client.get(`/api/v1/employees/${_id}/appraisal-history`, { params });
const createEmployeeAppraisal = ({ _id }: { _id: string }) => client.post(`/api/v1/employees/${_id}/appraisal`);
const updateEmployeeAppraisal = ({ _id, ...body }: { _id: string; values: AppraisalCreateType[] }) =>
  client.put(`/api/v1/employees/${_id}/appraisal`, body);

const activateUser = ({ id }: { id: number }) => client.post(`/api/v1/users/${id}/activate`);
const activateUsers = ({ ...body }: ActiveEmployeesRequestType): Promise<ActiveEmployeesResponseType> =>
  client.post(`/api/v1/users/activate`, body);

const generateEmployeeContract = ({ id, ...params }: GenerateContractParamsType): Promise<FileDataType> =>
  client.get(`/api/v1/employees/${id}/contracts/generate`, { params });
const getEmployeeContract = ({ _id, id }: { _id: string; id: number }): Promise<ContractType> =>
  client.get(`/api/v1/employees/${_id}/contracts/${id}`);
const getEmployeeLatest = ({ _id }: { _id: string }): Promise<ContractLastestType> =>
  client.get(`/api/v1/employees/${_id}/contracts/latest`);
const fetchEmployeeContract = ({ _id, ...params }: ContractParamsType): Promise<ContractPaginateType> =>
  client.get(`/api/v1/employees/${_id}/contracts`, { params });
const createEmployeeContract = ({ _id, ...body }: ContractCreateType) =>
  client.post(`/api/v1/employees/${_id}/contracts`, body);
const approveEmployeeContract = ({ _id, id, ...body }: ContractApproveType) =>
  client.post(`/api/v1/employees/${_id}/contracts/${id}/approve`, body);
const rejectEmployeeContract = ({ _id, id, ...body }: ContractRejectType) =>
  client.post(`/api/v1/employees/${_id}/contracts/${id}/reject`, body);
const sendEmployeeContractOtp = ({ _id, id }: { _id: string; id: number }) =>
  client.post(`/api/v1/employees/${_id}/contracts/${id}/otp`);
const changeKeyEmployee = ({ ...body }: ChangeKeyType) => client.post(`/api/v1/employees/me/key`, body);
const fetchCommitmentEmployee = ({ id, entId }: { id: number, entId: number }): Promise<ContractType> => client.post(`/api/v1/employees/${id}/getCommitmentEmployee/${entId}`);
const homepageSummary = (): Promise<EmployeeSummary> => client.get(`/api/v1/employees/me/sum`);
export default {
  getEmployeeById,
  fetchEmployees,
  fetchStatuses,
  updateEmployee,
  changeKeyEmployee,

  activateUser,
  activateUsers,

  getEmployeeMeeting,
  assignUserCrm,
  assignUserCa,

  fetchEmployeeReports,
  createEmployeeReport,

  fetchEmployeeWorkings,
  getEmployeeBankAccount,
  updateEmployeeBankAccount,
  getEmployeeBankAccountWorking,
  updateEmployeeBankAccountWorking,

  getEmployeeChecklistStatus,
  createEnterpriseChecklistStatus,
  fetchEmployeeChecklist,
  fetchEmployeeChecklistDoc,
  createEmployeeChecklistDoc,
  confirmEmployeeChecklistContract,

  getAppraisalById,
  getEmployeeAppraisal,
  fetchEmployeeAppraisal,
  createEmployeeAppraisal,
  updateEmployeeAppraisal,

  generateEmployeeContract,
  getEmployeeContract,
  getEmployeeLatest,
  fetchEmployeeContract,
  createEmployeeContract,
  approveEmployeeContract,
  rejectEmployeeContract,
  sendEmployeeContractOtp,
  fetchCommitmentEmployee,
  homepageSummary
};
