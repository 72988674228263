import { Trans } from '@lingui/macro';
import {
  Button,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { getEmployeeType } from 'constants/employee';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { employeeService, enterpriseService } from 'services';
import { formatNumber } from 'utils/common';


const COLORS = ['#b91c1c', '#404040'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CompanyListWorking = () => {
  const { id: _id } = useParams() as { id: string };
  const [dataSearch, onSearchChange] = useSearch({ _id });
  const { data: companys } = useQuery(
    ['enterpriseService.situationOfUsingLimit', dataSearch],
    () => enterpriseService.situationOfUsingLimit(dataSearch),
    { keepPreviousData: true },
  );
  const { data: summary } = useQuery(
    ['employee.homepageSummary',],
    () => employeeService.homepageSummary(),
    { keepPreviousData: true },
  );
  console.log(summary);
  const hanMucUngLuong = [
    { name: 'Hạn mức Sử dụng', value: summary?.totalAmount },
    { name: 'Hạn mức Khả dụng', value: summary?.availableAmount },
  ];
  const ngayCongKhaDung = [
    { name: 'Ngày công đã ứng', value: summary?.expectedLabor },
    { name: 'Ngày công Khả dụng', value: summary?.maxAdvanceLabor },
  ];
  const { data: items = [], paginate } = companys ?? {};
  const TongHanMuc = items.reduce((total, obj) => total + obj.advanceLimit, 0);
  const TongNgayCong = items.reduce((total, obj) => (total + obj.numbOfAvailableLabour), 0);
  return (
    <Grid container columnSpacing={4} rowSpacing={3}>
      <Grid item sm={6}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Company Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Labour Type</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Advance Limit (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Max Labor of Advance</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.enterprise.name}</TableCell>
                  <TableCell>{getEmployeeType(item.employeeType)}</TableCell>
                  <TableCell>{formatNumber(item.advanceLimit)}</TableCell>
                  <TableCell>{formatNumber(item.numbOfAvailableLabour)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2} className='text-center'>
                <span className='font-bold'><Trans>Total</Trans></span>
                </TableCell>
                <TableCell><span className='font-bold'>{formatNumber(TongHanMuc)}</span></TableCell>
                <TableCell><span className='font-bold'>{formatNumber(TongNgayCong)}</span></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className='flex justify-center'>
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </Grid>
      <Grid item sm={6}>
        <div className='flex items-center justify-around border rounded-lg py-4'>
          <div>
            <Typography variant='h5' className='text-center'>
              <Trans>HẠN MỨC ỨNG LƯƠNG</Trans>
            </Typography>
            <PieChart width={260} height={260}>
              <Pie
                data={hanMucUngLuong}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
                legendType='square'
                nameKey='name'
              >
                {hanMucUngLuong.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip formatter={formatNumber} />
              <Legend
                payload={hanMucUngLuong.map((item, index) => ({
                  id: item.name,
                  type: 'square',
                  value: `${item.name}: ${formatNumber(item.value)} VND`,
                  color: COLORS[index % COLORS.length],
                }))}
              />
            </PieChart>
          </div>
          <div>
            <Typography variant='h5' className='text-center'>
              <Trans>NGÀY CÔNG KHẢ DỤNG</Trans>
            </Typography>
            <PieChart width={260} height={260}>
              <Pie
                data={ngayCongKhaDung}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
                legendType='square'
              >
                {ngayCongKhaDung.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip formatter={formatNumber} />
              <Legend
                payload={ngayCongKhaDung.map((item, index) => ({
                  id: item.name,
                  type: 'square',
                  value: `${item.name}: ${formatNumber(item.value)} ngày`,
                  color: COLORS[index % COLORS.length],
                }))}
              />
            </PieChart>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default CompanyListWorking;
