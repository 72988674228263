import { t, Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { userService } from 'services';
import { sha256 } from 'utils/common';
import { usePermission } from 'hooks';

const UserPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const { requreUserPermission,ACCOUNT_USERPASS_UPDATE } = usePermission();
  const { mutate: updatePassword, isLoading } = useMutation(userService.updatePassword, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
    },
  });

  const handleClickSubmit = () => {
    handleSubmit(({ oldPassword, confirmPassword, password }) => {
      updatePassword({
        oldPassword: oldPassword,
        confirmPassword: confirmPassword,
        password: password,
      });
    })();
  };

  return (
    <div className='mt-6'>
      <Typography variant='h4' className='mb-4'>
        <Trans>Change Password</Trans>
      </Typography>

      <Grid container columnSpacing={4} rowSpacing={3}>
        <Grid item md={6} lg={4} xl={3}>
          <Controller
            name='oldPassword'
            defaultValue=''
            control={control}
            rules={{ required: t`Current Password is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                type='password'
                label={t`Current Password`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={6} lg={8} xl={9} sx={{ display: { md: 'block', xs: 'none' } }}></Grid>

        <Grid item md={6} lg={4} xl={3}>
          <Controller
            name='password'
            defaultValue=''
            control={control}
            rules={{ required: t`New Password is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                type='password'
                label={t`New Password`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={6} lg={8} xl={9} sx={{ display: { md: 'block', xs: 'none' } }}></Grid>

        <Grid item md={6} lg={4} xl={3}>
          <Controller
            name='confirmPassword'
            defaultValue=''
            control={control}
            rules={{ required: t`Confirm Password is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                type='password'
                label={t`Confirm Password`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={6} lg={8} xl={9} sx={{ display: { md: 'block', xs: 'none' } }}></Grid>
      </Grid>

      <div className='flex justify-start mt-4'>
        {requreUserPermission([ACCOUNT_USERPASS_UPDATE]) && (<LoadingButton variant='contained' className='w-32' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Update</Trans>
        </LoadingButton>)}
      </div>
    </div>
  );
};

export default UserPassword;
