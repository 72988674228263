import { Trans, t } from '@lingui/macro';
import { Box, Grid, MenuItem, Select, Typography, TextField } from '@mui/material';
import { formatNumber } from 'utils/common';
import { DesktopDatePicker } from '@mui/lab';
import { DateTime } from 'luxon';
import Calendar from 'react-calendar';
import { useState } from 'react';
import { AttendanceRetailByIdOutput } from 'types/EmployeeWorking';
import { useMutation, useQuery } from 'react-query';
import { enterpriseService } from 'services';
import { useActiveLocale } from 'hooks/useActiveLocale';
import 'views/Epayz/Dashboard/Employee/components/AtteendanceRetails.css';
import $ from "jquery";
const AttendanceRetail = () => {
  const [date, onChangeDate] = useState(new Date());
  const [valueInfo, onChangeInfo] = useState({} as AttendanceRetailByIdOutput);
  const [ent, changeEnt] = useState(0)
  const [value, onChange] = useState([] as Date[]);
  const userLocale = useActiveLocale();
  const handleRefresh = (e: any) => {
    if (e.action == 'next' || e.action == 'prev' || (e.action == 'drillDown' && e.view == 'month')) {
      onChangeDate(e.activeStartDate);
      changeValueInfo({ id: ent, month: e.activeStartDate.getMonth() + 1, year: e.activeStartDate.getFullYear() });
    }
  }
  const { mutate: changeValueInfo, isLoading } = useMutation(enterpriseService.attendanceretail, {
    onSuccess: (data) => {
      $(".react-calendar")
        .find(`.react-calendar__month-view__days__day--weekend`)
        .removeClass("react-calendar__month-view__days__day--weekend")
      onChangeInfo(data);
      if (data.ngayBatDau && data.ngayKetThuc) {
        var lsta = [] as Date[];
        for (var i = 0; i < data.ngayHienTai.length; i++) {
          lsta.push(new Date(data.ngayHienTai[i]));
        }
        onChange(lsta);
      } else {
        onChange([]);
      }
    },
  });
  const handleChangeMonth = () => {
    setTimeout(() => {
      $.each($(".react-calendar").find(`.react-calendar__month-view__weekdays__weekday`), function (i, val) {
        var datatitle = $(this).find("abbr").attr("title")
        $(this).find("abbr").text(datatitle ? datatitle : '');
      })
    })
  }
  const { data } = useQuery(
    ['enterpriseService.getAllSituationOfUsingLimit'],
    () => enterpriseService.getAllSituationOfUsingLimit(),
    { keepPreviousData: false,
      onSuccess: (data) => {
          if(data.data.length > 0) {
            let check = data.data.filter(function(x){
              return x.id === ent
            }).length;
            changeEnt(check === 0 ? data.data[0].id : ent)
            changeValueInfo({ id: (check === 0 ? data.data[0].id : ent), month: date.getMonth() + 1, year: date.getFullYear() });
          }
      },
    },
  );
  const { data: items = [], paginate = { size: 1000, page: 1 }} = data ?? {};
  console.log(items);
  return (
    <Grid container columnSpacing={4} rowSpacing={3}>
      <Grid item sm={3}>
        <Select className='my-4' size='small' fullWidth displayEmpty inputProps={{ 'aria-label': 'Without label' }}
          value={ent}
          onChange={(e: any) => { changeEnt(e.target.value); changeValueInfo({ id: e.target.value, month: date.getMonth() + 1, year: date.getFullYear() }); }}
        >
          {items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Trans>{item.enterprise.name}</Trans>
            </MenuItem>
          ))}
        </Select>
        <Box
          sx={{
            padding: 2,
            backgroundColor: '#ccc',
          }}
        >
          <div className='flex items-center justify-between'>
            <Typography variant='subtitle1'>{userLocale == 'vi-VN' ? "Tổng công dự kiến" : "Total Projects"}</Typography>
            <div>{formatNumber(valueInfo.tongCongDuKien)}</div>
          </div>
          <div className='flex items-center justify-between'>
            <Typography variant='subtitle1'>{userLocale == 'vi-VN' ? "Tổng công đã thực hiện" : "Total To Performed"}</Typography>
            <div>{formatNumber(valueInfo.tongCongDaThucHien)}</div>
          </div>
        </Box>
      </Grid>
      <Grid item sm={9}>
        <Calendar onActiveStartDateChange={handleRefresh} onClickMonth={handleChangeMonth} prevLabel={"▶"} nextLabel={"▶"} navigationLabel={({ date, label, locale, view }) => (userLocale == 'vi-VN' ? `Tháng ${date.getMonth() + 1},${date.getFullYear()}` : `Month ${date.getMonth() + 1},${date.getFullYear()}`)} locale={userLocale} value={value} />
        <p className='calendarInfoUpdate'>{(userLocale == 'vi-VN' ? `Dữ liệu được cập nhật lần cuối ngày ` : `The data was last updated on `) + `${valueInfo.ngayCapNhat ? DateTime.fromISO(valueInfo.ngayCapNhat).toFormat('dd/MM/yyyy') : ''}`}</p>
      </Grid>
    </Grid>
  );
};

export default AttendanceRetail;
