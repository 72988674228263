import { Log, SessionStatus, SignoutResponse, User, UserManager, WebStorageStateStore } from 'oidc-client';
import { Constants } from '../helpers/Constants';
import { store } from 'reducers';
import { ProfileState, signIn, signOut, signOutNoCallbackSSO } from 'reducers/profile';
export interface IAuthService {
  getUser(): Promise<User | null>;
  login(): Promise<void>;
  logout(): Promise<void>;
  renewToken(): Promise<User>;
  signinCallback(): Promise<User>;
  signoutCallback(): Promise<void | SignoutResponse>;
  checkStatusSession() : Promise<SessionStatus>;
  removeUser() : Promise<void>;
  clearStaleState() : Promise<void>;
  startSilentRenew() : void;
  stopSilentRenew() : void;
}
export default class AuthService implements IAuthService {
  private _userManager: UserManager;
  constructor() {
    const settings = {
      authority: Constants.stsAuthority,
      client_id: Constants.clientId,
      redirect_uri: `${Constants.clientRoot}signin-callback`,
      silent_redirect_uri: `${Constants.clientRoot}silent-renew.html`,
      client_secret: 'this is my custom Secret key for authentication',
      // tslint:disable-next-line:object-literal-sort-keys
      post_logout_redirect_uri: `${Constants.clientRoot}`,
      response_type: 'code',
      scope: Constants.clientScope,
      monitorSession: true,
      checkSessionInterval: 100,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    };
    this._userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;

    // this._userManager.events.addAccessTokenExpired(() => console.log("expired!"));
    // this._userManager.events.addUserSessionChanged(() => console.log("changed!"));
    // this._userManager.events.addUserUnloaded(() => console.log("unload!"));
    // this._userManager.events.addSilentRenewError(() => console.log("silentrenew error!"));
    // this._userManager.events.addUserSignedOut(()=> {
    //   console.log("user signed out");
    //   // var urlcallback = /*(Constants.stsAuthority ? Constants.stsAuthority : '')*/ "https://localhost:5001/" + "Account/LogoutCallBack?clientId=" + (Constants.clientId ? Constants.clientId : '');
    //   // console.log("callback: " + urlcallback)
    //   //store.dispatch(signOut());
    //   this._userManager.removeUser().then(()=>{
    //     store.dispatch(signOutNoCallbackSSO());
    //   }).finally(()=>{
    //     window.location.href = "/";
    //   });
    // });
  }

  public getUser(): Promise<User | null> {
    return this._userManager.getUser();
  }

  public login(): Promise<void> {
    return this._userManager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return this._userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this._userManager.signoutRedirect();
  }

  public signinCallback(): Promise<User> {
    return this._userManager.signinRedirectCallback();
  }

  public signoutCallback(): Promise<void | SignoutResponse> {
    return this._userManager.signoutRedirectCallback();
  }

  public checkStatusSession() : Promise<SessionStatus>{
    return this._userManager.querySessionStatus();
  }

  public removeUser() : Promise<void>{
    return this._userManager.removeUser();
  }

  public clearStaleState() : Promise<void>{
    return this._userManager.clearStaleState();
  }

  public startSilentRenew() : void{
    return this._userManager.startSilentRenew();
  }
  public stopSilentRenew() : void{
    return this._userManager.stopSilentRenew();
  }
}
