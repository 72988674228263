import * as React from 'react';
import AuthService, {IAuthService} from './services/AuthService';
import { useNavigate } from 'react-router-dom';
import { store } from 'reducers';
import { signIn, signOut } from 'reducers/profile';
import { userService } from 'services';
import { openAlert } from 'reducers/notification';
import { t, Trans } from '@lingui/macro';
import { Constants } from 'helpers/Constants';
import { IS_EPAYZ, IS_SSO } from 'env';
import { useState } from 'react';
import { epayzRoute, privateRoute } from 'routes';
const authService: IAuthService = new AuthService();
const SigninCallback = () => {
    const [callbackStatus, changecallbackStatus] = useState(false);
    const [runGetProfile, runGetProfileStatus] = useState(false);
    const navigate = useNavigate();
    React.useEffect(() => {
        (async () => { 
            try {
                console.log("Signing in...");

                var userResponse = await authService.getUser();
                if(!userResponse){
                    await authService.signinCallback();
                    userResponse = await authService.getUser();
                }
                if (userResponse) {
                    changecallbackStatus(true);
                    runGetProfileStatus(true);
                    if(userResponse.access_token){
                        store.dispatch(signIn({ accessToken: userResponse.access_token, refreshToken: userResponse.refresh_token}));
                            await userService.getProfile({ getlink: false}).then((profile) => {
                            if (!profile) {
                            store.dispatch(
                                openAlert({
                                message: t`Username or password is incorrect`,
                                code: 400,
                                variant: 'error',
                                }),
                            );
                            console.log("sign out");
                            store.dispatch(signOut());
                            return;
                            }
                            console.log("sign in");
                            store.dispatch(signIn(profile));
                            window.location.href = "/login";
                            // if(!IS_EPAYZ){
                            //     navigate('/');
                            // }else{
                            //     if(callbackStatus == true) {
                            //         //navigate(privateRoute.home.path, { replace: true });
                            //         window.location.href = "/login";
                            //     }else{
                            //         await authService.login();
                            //     }
                            // }
                        });
                    }else{
                        await authService.clearStaleState();
                        window.location.href= Constants.stsAuthority + 'Account/AccessDenied';
                    }
                }
            }
            catch(e) {
                console.error(e);
            }
            finally {
                if(!IS_EPAYZ){
                    navigate('/');
                }else{
                    if(callbackStatus == true) {
                        //navigate(privateRoute.home.path, { replace: true });
                        // setInterval(function(){
                        //     if(runGetProfile == false){
                        //         window.location.href = "/login";
                        //     }
                        // }, 1000)               
                    }else{
                        await authService.clearStaleState();
                        await authService.login();
                    }
                }
            }
        })();
    });
  
    return <div>
                <img src={require('assets/images/UntitledWhite.png')} style={{ width: '100%', height: 'calc(100vh)' }} />
            </div>;
  };
  
  export default SigninCallback;
