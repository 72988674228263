import { Trans } from '@lingui/macro';
import { Grid, Paper, Typography } from '@mui/material';
import { EnterpriseFrequency } from 'views/Enterprise/EnterpriseView/components';
import { PaymentSchedule, UsingLimit } from 'views/Epayz/Dashboard/Enterprise/components';

const EpayzDashboardEnterprise = () => {
  return (
    <Paper>
      <div className='p-4'>
        <Grid container columnSpacing={4}>
          <Grid item sm={4}>
            <Typography variant='h4' className='text-center'>
              <Trans>USING LIMIT</Trans>
            </Typography>
            <UsingLimit />
          </Grid>
          <Grid item sm={8}>
            <Typography variant='h4' className='text-center'>
              <Trans>PAYMENT SCHEDULE OF SALARY & FEE</Trans>
            </Typography>
            <PaymentSchedule />
          </Grid>
        </Grid>
        {/* <Typography variant='h4' className='mt-4'>
          <Trans>FREQUENCY OF UPDATE & NOTICE</Trans>
        </Typography> */}
        <EnterpriseFrequency />
      </div>
    </Paper>
  );
};

export default EpayzDashboardEnterprise;
