export class Constants {
  public static stsAuthority = process.env.REACT_APP_SSO_HOST;// "https://scp-sso.bnnsoft.vn/" 'https://localhost:5001/';
  public static clientId = process.env.REACT_APP_SSO_CLIENTID;
  public static clientRoot = process.env.REACT_APP_SSO_CLIENT;// 'https://localhost:3800/';
  public static clientScope = 'openid profile api1';

  public static apiRoot = process.env.REACT_APP_SSO_HOST + "api"; //'https://localhost:5001/api/';

  public static checkPassUrl(url: string): boolean {
    var lsturl = ["/signin-callback", "/register", "/signout-callback"];
    if(process.env.REACT_APP_IS_EPAYZ){
      lsturl = lsturl.concat(["/login","/salary-advance", "/brokerage-advance", "/partners", "/about-us", "/privacy-policy", "/terms-of-use"]);
    }
    for (var i = 0; i < lsturl.length; i++){
      if(url.indexOf(lsturl[i]) != -1){
        return false;
      }
    }
    return true;
  }
}