import * as React from 'react';
import AuthService, {IAuthService} from './services/AuthService';
import { useNavigate } from 'react-router-dom';
import { store } from 'reducers';
import { signIn, signOut } from 'reducers/profile';
import { userService } from 'services';
import { openAlert } from 'reducers/notification';
import { t, Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
const authService: IAuthService = new AuthService();
const SignoutCallback = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    React.useEffect(() => {
        (async () => { 
            try {
                dispatch(signOut());
            }
            catch(e) {
                console.error(e);
            }
            finally {
                navigate('/');
            }
        })();
    });
  
    return <div>
                <img src={require('assets/images/UntitledWhite.png')} style={{ width: '100%', height: 'calc(100vh)' }} />
            </div>;
  };
  
  export default SignoutCallback;
