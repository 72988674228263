import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper } from '@mui/material';
import { formatNumber } from 'utils/common';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { transactionService } from 'services';
import { Trans, t } from '@lingui/macro';
import { enterpriseService } from 'services';
const BusinessResult = () => {
  const curYear = DateTime.now().year;
  const [year, setYear] = useState(curYear);
  const { data: statistic } = useQuery(
    ['transactionService.statisticTransYear', { year }],
    () => transactionService.statisticTransYear({ year }),
    { keepPreviousData: true },
  );
  const data = statistic?.months.map((item) => ({ ...item, name: item.month + '/' + item.year }));

  const { data: allTrans } = useQuery(
    ['transactionService.statisticTrans', { dateFrom: '', dateTo: '' }],
    () => transactionService.statisticTrans({ dateFrom: '', dateTo: '' }),
    { keepPreviousData: false },
  );
  const { data: ents } = useQuery(
    ['enterpriseService.getClientOnboard', { type : "ENTERPRISE" }],
    () => enterpriseService.getClientOnboard({ type : "ENTERPRISE" }),
  );
  const { data: emps } = useQuery(
    ['enterpriseService.getClientOnboard', { type : "EMPLOYEE" }],
    () => enterpriseService.getClientOnboard({ type : "EMPLOYEE" }),
  );
  return (
    <div className='border rounded-lg mt-2 p-4'>
      <Paper elevation={0} className='w-full' style={{ height: '400px' }}>
        <ResponsiveContainer>
          <LineChart width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 0,
              bottom: 20,
              left: 40,
            }}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey='name' />
            <YAxis yAxisId='left' tickFormatter={formatNumber} />
            <Tooltip formatter={formatNumber} />
            <Legend />
            <Line yAxisId='left' type='monotone' dataKey='totalRequestAmount' name={t`Giá trị Giao dịch`} stroke='#b91c1c' />
            <Line yAxisId='left' type='monotone' dataKey='averageAmount' name={t`Số dư Bình quân`} stroke='#404040' />
          </LineChart>
        </ResponsiveContainer>
      </Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Enterprise</TableCell>
              <TableCell>Employee</TableCell>
              <TableCell><Trans>Giá trị Giao dịch (VND)</Trans></TableCell>
              <TableCell><Trans>Số dư Bình quân (VND)</Trans></TableCell>
              <TableCell><Trans>Số Giao dịch</Trans></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className='text-center'>{ents?.join}/{ents?.wait}</TableCell>
              <TableCell className='text-center'>{emps?.join}/{emps?.wait}</TableCell>
              <TableCell className='text-center'>{formatNumber(allTrans?.totalRequestAmount)}</TableCell>
              <TableCell className='text-center'>{formatNumber(allTrans?.averageAmount)}</TableCell>
              <TableCell className='text-center'>{formatNumber(allTrans?.numOfTrans)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BusinessResult;
